<template>
	<div v-if="loaded">
		<div class="c-app-form__tools">
			<button class="c-app-form__tools-back" @click.prevent="actions.setStep(state.step - 1)" v-if="state.step > 1 && !conditions.isBusyOrComplete()" aria-label="Back"></button>

			<ol class="c-app-form__step-list" role="navigation" v-if="state.step > 0">
				<li class="c-app-form__step-list-item" :aria-current="state.step === 1">
					<button class="c-app-form__step-list-btn" @click.prevent="actions.setStep(1)">
						<span class="c-app-form__step-list-number" aria-label="Step 1">1</span>
						<span class="c-app-form__step-list-complete iconf-tick" aria-label="Step 1 (complete)"></span>
					</button>
					<span v-if="content.Step1EstimatedTime" class="c-app-form__step-list-title">{{content.Step1EstimatedTime}}</span>
				</li>
				<li class="c-app-form__step-list-item" :aria-current="state.step === 2">
					<button class="c-app-form__step-list-btn" @click.prevent="actions.setStep(2)">
						<span class="c-app-form__step-list-number" aria-label="Step 2">2</span>
						<span class="c-app-form__step-list-complete iconf-tick" aria-label="Step 2 (complete)"></span>
					</button>
					<span v-if="content.Step2EstimatedTime" class="c-app-form__step-list-title">{{content.Step2EstimatedTime}}</span>
				</li>
				<li class="c-app-form__step-list-item" :aria-current="state.step === 3">
					<button class="c-app-form__step-list-btn" @click.prevent="actions.setStep(3)">
						<span class="c-app-form__step-list-number" aria-label="Step 3">3</span>
						<span class="c-app-form__step-list-complete iconf-tick" aria-label="Step 3 (complete)"></span>
					</button>
					<span v-if="content.Step3EstimatedTime" class="c-app-form__step-list-title">{{content.Step3EstimatedTime}}</span>
				</li>
				<li class="c-app-form__step-list-item" :aria-current="state.step === 4">
					<button class="c-app-form__step-list-btn" @click.prevent="actions.setStep(4)">
						<span class="c-app-form__step-list-number" aria-label="Step 4">4</span>
						<span class="c-app-form__step-list-complete iconf-tick" aria-label="Step 4 (complete)"></span>
					</button>
					<span v-if="content.Step4EstimatedTime" class="c-app-form__step-list-title">{{content.Step4EstimatedTime}}</span>
				</li>
			</ol>

			<div class="c-app-form__app-ref" v-if="state.appReference">
				<dl class="c-app-form__app-ref-wrap">
					<dt class="c-app-form__app-ref-label">Application ID:</dt>
					<dd class="c-app-form__app-ref-value">{{state.appReference}}</dd>
				</dl>
				<teraform-tooltip :text="content.TooltipAppReference" :class="'c-tooltip--small'" />
			</div>
		</div>

		<div v-if="content.HasApplicationInProgress && content.ApplicationInProgressMessage" class="c-app-form__alert">
			<div class="c-notification o-portal-status--warning">
				<div class="c-notification__bar">
					<span class="c-notification__icon iconf-alert" aria-hidden="true"></span>
				</div>
				<div class="c-notification__body">
					<div class="c-notification__content" v-html="content.ApplicationInProgressMessage" />
					<div v-if="content.ApplicationInProgressLink" class="c-notification__actions">
						<a class="o-btn o-btn--small o-btn--bordered-portal o-portal-status--warning" :href="content.ApplicationInProgressLink.Url" :target="content.ApplicationInProgressLink.Target">{{content.ApplicationInProgressLink?.Name}}</a>
					</div>
				</div>
			</div>
		</div>

		<ul class="c-app-form__steps" aria-live="polite" @change="setFormDirtyState()">
			<li v-if="state.step == 1">
				<teraform-step1 :inputs="inputs" :content="content" :actions="actions" :state="state" :conditions="conditions" :stepInputs="stepInputs" :localState="localState" :config="config" />
			</li>
			<li v-if="state.step == 2">
				<teraform-step2 :inputs="inputs" :content="content" :actions="actions" :state="state" :conditions="conditions" :stepInputs="stepInputs" :localState="localState" :config="config" />
			</li>
			<li v-if="state.step == 3">
				<teraform-step3 :inputs="inputs" :content="content" :actions="actions" :state="state" :conditions="conditions" :stepInputs="stepInputs" :localState="localState" :config="config" />
			</li>
			<li v-if="state.step == 4">
				<teraform-step4 :inputs="inputs" :content="content" :actions="actions" :state="state" :conditions="conditions" :stepInputs="stepInputs" :localState="localState" :config="config" />
			</li>
		</ul>
	</div>
</template>
<script>
	import $ from 'jquery';
	import { modal } from 'App/modal/modal';
	import { qs } from 'Util/querystring';
	import * as Keybinding from 'Util/keybinding';

	import { Teraform } from 'Vue/teraform/teraform';
	import { Helpers, Rules, Patterns, ErrorTypes } from 'Vue/teraform/common';

	import { recaptcha } from 'App/recaptcha/recaptcha';
	import { assist } from 'Util/assist';
	import { publish, subscribe } from 'Util/pubsub';
	import { debounce } from 'Util/debounce';

	const selectors = {
		appForm: '.js-app-form',
		appFormForm: '.js-app-form__form',
		appFormStep: '.js-app-form__step',
		help: '.js-app-form__help',

		addressStatus: '.js-app-form__address-status',
		addressAutocomplete: '.js-app-form__address-autocomplete',
		addressAutocompleteInput: '.js-app-form__address-autocomplete-input',
		addressResultItem: '.js-app-form__address-result-item',

		range: '.js-calculator-range',
		rangeInput: '.js-calculator-range-input',

		dataErrorLogging: '.js-app-form__logging-data',
		validationErrorLogging: '.js-app-form__logging-validation',

		tooltip: '.js-tooltip',

		preSaveCheckModalCancel: '.js-app-form__pre-save-check-cancel',
		preSaveCheckModalContinue: '.js-app-form__pre-save-check-continue',
	};

	const errorModalIds = {
		save: 'app-save-error-modal',
		session: 'app-session-error-modal',
		data: 'app-data-error-modal',
		preSaveCheck: 'app-pre-save-check-error-modal',
		generic: 'app-generic-error-modal',
	};

	const classes = {
		helpHint: 'c-app-form--help-hint',
		zoom: 'c-form__range--zoom'
	};

	const pubsubEvents = {
		modelOpen: '/modal/open',
		expandCollapseClose: '/expandcollapse/close',
		tooltipAttentionAll: 'tooltip/attentionAll',
		tooltipOpened: 'tooltip/opened',
	};

	const api = {
		preSaveCheck: '/api/loan-app/pre-save-check',
	};

	const preSaveCheckErrorMessages = {
		checkFailed: 'pre-save check failed',
	};

	const SpecialReferrers = {
		JointBorrower: 'JointBorrower'
	};

	/**
		* Length of time (seconds) a "pause" should last before
		* tooltips trigger their "attention grabber" animation
		*
		* @type {number}
		*/
	let pauseDuration = 10;
	let pauseTimeout = null;

	/**
		* Length of time (seconds) between animations while the
		* user is "paused". Note, this time includes the animation time.
		*
		* @type {number}
		*/
	let pauseRepeatDuration = 1.5;
	let pauseRepeatInterval = null;
	let pauseRepeatAllowed = true;

	const minAge = 18;
	const maxAge = 100;
	const dateInputNames = ['DateOfBirth', 'VisaExpiry', 'PassportExpiry'];
	const numberInputNames = ['LoanAmountSpecific'];

	const localStorageSupport = !!window.localStorage && ('setItem' in window.localStorage);

	const localStorageKeys = {
		calculatorAmount: 'calculator-amount',
		defaultPreferredBranch: 'default-preferred-branch',
		previousStepValidationError: 'previous-step-validation-error'
	};

	let step2MissingData;
	let step3MissingData;

	// TODO IF-729 HttpStatusCode to check for recaptcha failure
	let recaptchaFallbackRequiredStatusCode = 409;
	let initRecaptchaFallbackDone = false;

	let isFormDirty = false;

	let setup = function ($scope, config) {

		$scope.localState = {
			busy: false,
			submitting: false,
			referrer: null,
			recaptchaInitialised: false,
			lockedPurpose: null,
			recommendBranchText: null,
			recaptchaFallbackRequired: false,
			recaptchaFallbackCompleted: false,
		};

		let initialState = {
			result: null,
			appReference: null,
			step: 1,
			preSaveCheckDone: false,
			UserAgent: navigator.userAgent,
			specifyLoanAmount: false,
			presetPreferredBranch: false,
			jointApplication: false,
			secondaryApplication: false,
			referrerDataToken: null,
			stepLocked: {
				1: true,
				2: true,
				3: true,
				'4-security': true,
				'4-employment': true
			}
		};

		let initRecaptcha = function () {
			recaptcha.init({
				apiKey: config.recaptcha.apiKey,
				action: 'loan_app',
			});
			$scope.localState.recaptchaInitialised = true;
		};

		initRecaptcha();

		let logic = {
			utils: {
				encodeDate: function (date) {
					var dateString;

					// Use '/' instead of '-' because Chrome doesn't interpret yyyy-MM-dd
					// strings correctly when passed to new Date()
					dateString = date.getFullYear() + '/' +
						logic.utils._addLeadingZero(date.getMonth() + 1) + '/' +
						logic.utils._addLeadingZero(date.getDate())
						;

					return dateString;
				},

				decodeDate: function (dateString) {
					// yyyy/MM/dd
					var slashes = /\d{4}\/\d{2}\/\d{2}/;

					// yyyy-MM-ddThh:mm:ss
					var dashes = /\d{4}\-\d{2}\-\d{2}T[0-9:]+/;

					// yyyy-MM-dd
					var basicDashes = /\d{4}\-\d{2}\-\d{2}/;

					var date;

					if (!dateString) {
						return;
					}

					if (slashes.test(dateString) === true) {
						date = new Date(dateString);
					} else if (dashes.test(dateString) === true) {
						date = new Date(dateString);
						if (isNaN(date.getDate())) {
							throw new Error('Datestring was invalid');
						}
					} else if (basicDashes.test(dateString) === true) {
						date = new Date(dateString);
						if (isNaN(date.getDate())) {
							throw new Error('Datestring was invalid');
						}
					} else {
						throw new Error('Datestring be in one of the formats yyyy/MM/dd or yyyy-MM-ddThh:mm:ss or yyyy-MM-dd');
					}

					date = new Date(dateString);

					return date;
				},

				getTodayString: function () {
					var now = new Date();
					var year = now.getFullYear();
					var month = (now.getMonth() + 1).toString().padStart(2, '0');
					var day = (now.getDate()).toString().padStart(2, '0');

					var todayString = year + '-' + month + '-' + day;

					return todayString;
				},

				_addLeadingZero: function (number) {
					var string = number.toLocaleString('en-nz', { minimumIntegerDigits: 2 });
					return string;
				},

				_toDollarString: function (number) {
					var options = {
						// style: 'currency',
						// currency: 'NZD'
						style: 'decimal',
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					};

					var dollarString = number.toLocaleString('en-nz', options);

					return dollarString;
				},

				assertInteger: function (inputValue) {
					var value = +inputValue;

					var isInteger = value === Math.round(value);

					return isInteger;
				},
			},
			consent: {
				rememberPrivacyPolicyVersion: function () {
					if ($scope.inputs.AgreedToPrivacyPolicy.value === true) {
						$scope.inputs.PrivacyPolicyVersion.value = config.loanApplication.privacyPolicyVersion;
					} else {
						$scope.inputs.PrivacyPolicyVersion.value = null;
					}
				},
				rememberMarketingPolicyVersion: function () {
					if ($scope.inputs.AgreedToElectronicCommunication.value === true) {
						$scope.inputs.MarketingPolicyVersion.value = config.loanApplication.marketingPolicyVersion;
					} else {
						$scope.inputs.MarketingPolicyVersion.value = null;
					}
				}
			},
			phoneNumber: {
				getPlaceholder: function (mobilePlaceholder, landlinePlaceholder) {
					var value = $scope.inputs.ContactNumberType.value;
					var isMobile = value === 'Mobile';
					var placeholder;

					if (isMobile) {
						placeholder = mobilePlaceholder;
					} else {
						placeholder = landlinePlaceholder;
					}

					return placeholder;
				}
			},
			loanAmount: {
				toggleSpecific: function (e) {
					e.preventDefault();

					if ($scope.state.specifyLoanAmount === true) {
						logic.loanAmount._hideSpecific();
					} else {
						logic.loanAmount.setSpecific();
					}
				},
				forget: function () {
					if (localStorageSupport && localStorage.getItem(localStorageKeys.calculatorAmount)) {
						localStorage.removeItem(localStorageKeys.calculatorAmount);
					}
				},
				setSpecific: function (keepInitialValue) {
					$scope.state.specifyLoanAmount = true;
					if (!keepInitialValue) {
						$scope.inputs.LoanAmountSpecific.value = inputs.LoanAmount.value;
					}
				},
				_hideSpecific: function () {
					$scope.state.specifyLoanAmount = false;
					$scope.inputs.LoanAmountSpecific.value = '';
				}
			},
			referrer: {
				setLoanDetails: function () {
					var queryStringObj = qs.get();
					var referrer = queryStringObj.referrer;
					var alreadyHasReferrer = !!$scope.inputs.Referrer.value;

					var referrerDataToken = queryStringObj['referrer-data'];
					var alreadyHasReferrerDataToken = !!$scope.state.referrerDataToken;

					var amount;
					var amountValidMin;
					var amountValidMax;
					var amountValidCustom;
					var amountValid;

					var purpose;
					var purposeValid;

					var relatedApplicationId;

					// Initialise referrer from querystring parameter if necessary
					if (referrer && alreadyHasReferrer === false) {
						$scope.inputs.Referrer.value = referrer;
					}

					if (referrerDataToken && alreadyHasReferrerDataToken === false) {
						$scope.state.referrerDataToken = referrerDataToken;
					}

					if ($scope.inputs.Referrer.value) {
						$scope.state.referrer = logic.referrer._getReferrerConfig($scope.inputs.Referrer.value);
						if (conditions.isBroker()) {
							// Brokers always use the LoanAmountSpecific field.
							logic.loanAmount.setSpecific(true);
						}

						if (!$scope.state.referrer) {
							// This error will be handled already on the BE
							// If a referrer is in the query string, it will be valid
							logic.referrer._handleInvalidQuerystring();
						} else {
							// Initialise pre-filled values only if there is a referrer
							logic.referrer.prefillFields();
						}

						// Amount
						if (alreadyHasReferrer === false) {
							amount = +queryStringObj.amount;
							// Validate that the amount would also pass standard loan amount validation rules
							amountValidMin = amount >= $scope.inputs.LoanAmountSpecific.validation.min;
							amountValidMax = amount <= $scope.inputs.LoanAmountSpecific.validation.max;
							amountValidCustom = $scope.inputs.LoanAmountSpecific.validation.custom(amount);

							amountValid = amountValidMin &&
								amountValidMax &&
								amountValidCustom;
						} else {
							amount = $scope.state.specifyLoanAmount
								? $scope.inputs.LoanAmountSpecific.value
								: $scope.inputs.LoanAmount.value;
							amountValid = true;
						}

						// Purpose
						if (alreadyHasReferrer === false) {
							purpose = queryStringObj.purpose;
							if (purpose) {
								purpose = purpose.replace(/\+/g, ' ');
							}
							purposeValid = purpose && purpose.length;
						} else {
							purpose = $scope.inputs.LoanPurpose.value;
							purposeValid = true;
						}

						// ID of related application
						relatedApplicationId = queryStringObj['related-id'];

						if (typeof relatedApplicationId === 'undefined') {
							relatedApplicationId = null;
						}

						var isJointBorrowerReferrer = $scope.inputs.Referrer.value === SpecialReferrers.JointBorrower;
						var isBroker = conditions.isBroker();
						var mayBeSecondaryApplication = isJointBorrowerReferrer || isBroker;
						if (mayBeSecondaryApplication && relatedApplicationId) {
							$scope.state.secondaryApplication = true;
							$scope.inputs.JointApplication.value = 'Yes';
							$scope.inputs.RelatedApplicationId.value = relatedApplicationId;
						}

						// Don't try to set this off the query string unless the referrer is being set from there
						if (conditions.isPurposeLocked()) {
							if (purposeValid) {
								if (alreadyHasReferrer === false) {
									$scope.inputs.LoanPurpose.value = purpose;
								}
								$scope.localState.lockedPurpose = purpose;
							} else {
								logic.referrer._handleInvalidQuerystring();
							}
						}

						if (conditions.isAmountLocked()) {
							if (amountValid) {
								// inputs.LoanAmountSpecific.value already set,
								// and setSpecific called, in prefillFields()

								if (!$scope.inputs.LoanAmountDescription.value) {
									if (config.referrerContent) {
										$scope.inputs.LoanAmountDescription.value = config.referrerContent.LoanAmountDescription;
									} else {
										$scope.inputs.LoanAmountDescription.value = '$' + logic.utils._toDollarString(parseInt(amount, 10));
									}
								}
							} else {
								logic.referrer._handleInvalidQuerystring();
							}
						}

						if (config.referrerContent && !$scope.inputs.ReferrerData.value) {
							$scope.inputs.ReferrerData.value = config.referrerContent.Products.map(function (product) {
								// Decode special values encoded on the advanced referrer end of things
								var name = product.Name;

								name = name
									.replace(/&amp;/g, '&')
									.replace(/&lt;/g, '<')
									.replace(/&gt;/g, '>')
									.replace(/&quot;/g, '"');

								product.Name = name;
								return product;
							});
						}
					}
				},
				_getReferrerConfig: function (referrerName) {
					var i;
					var referrer;

					for (i = 0; i < config.referrer.length; i++) {
						referrer = config.referrer[i];

						if (referrer.key === referrerName) {
							return referrer;
						}
					}
				},
				_handleInvalidQuerystring: function () {
					var queryStringObj = qs.get();

					delete queryStringObj.referrer;
					delete queryStringObj.amount;
					delete queryStringObj.purpose;
					delete queryStringObj['related-id'];

					qs.set(queryStringObj);
				},

				prefillFields: function () {
					var queryStringObj = qs.get();

					var prefillFieldsMap = {
						'amount': inputs.LoanAmountSpecific,
						'first-name': inputs.FirstName,
						'last-name': inputs.LastName,
						'email': inputs.Email,
						'broker-email': inputs.BrokerEmailSubmission,
					};

					var key;
					var input;
					var value;

					for (key in prefillFieldsMap) {
						input = prefillFieldsMap[key];
						value = queryStringObj[key];

						if (!isNaN(parseFloat(value))) {
							value = parseFloat(value);
						}

						if (value && !input.value) {
							if (key === 'amount') {
								logic.loanAmount.setSpecific();
							}

							input.value = value;
						}
					}
				},
				validateQuerystring: function () {
					// If the form has a referrer, ensure it exists in the querystring
					var referrer = $scope.inputs.Referrer.value;
					var querystring = qs.get();

					if (referrer && querystring.referrer !== referrer) {
						querystring.referrer = referrer;
						qs.set(querystring);
					}
				},
			},
			preferredBranch: {
				condition: function () {
					var isExistingCustomer = $scope.inputs.ExistingCustomer.value === 'Yes';

					return isExistingCustomer;
				},
				getValues: function (branches) {
					var values = [];
					var i;
					var branch;

					// skip first option - this should be default 'online'
					for (i = 0; i < branches.length; i++) {
						branch = {
							displayValue: branches[i].name,
							value: branches[i].id + ''
						};

						values.push(branch);
					}
					values = [
						{
							displayValue: 'Please select',
							value: null
						}
					].concat(values);

					return values;
				},
				findClosest: function (lat, long) {
					var branches = config.creditSense.branches;

					var i;
					var branch;
					var distance;

					var closestBranch;
					var closestBranchDistance;

					for (i = 0; i < branches.length; i++) {
						branch = branches[i];
						distance = logic.location.latLongDist(lat, long, branch.lat, branch.lng);

						if ((!closestBranch) || distance < closestBranchDistance) {
							closestBranch = branch;
							closestBranchDistance = distance;
						}
					}

					return closestBranch;
				},
				setRecommendedBranchText: function () {
					if ($scope.state.recommendedBranch) {
						if (config.loanApplication.recommendBranchText) {
							$scope.localState.recommendBranchText = config.loanApplication
								.recommendBranchText.replace(
									'{branch}',
									$scope.state.recommendedBranch.name
								);
						}
					}
				},
				setDefaultRecommendedBranch: function () {
					var queryStringObj = qs.get();
					var qsBranchName = queryStringObj.branch;

					var matchingBranch;

					var i;
					var branch;
					var branchName;

					var defaultPreferredBranch;
					var validBranchIds;

					var matchBranchNames = function (a, b) {
						var match;

						if (a && b) {
							// Remove whitespace and ignore case
							a = a.replace(/\s/g, '').toLowerCase();
							b = b.replace(/\s/g, '').toLowerCase();

							match = a === b;

							return match;
						} else {
							return false;
						}
					};

					if ($scope.state.presetPreferredBranch === true) {
						// A branch has already been preset, don't change it
						return;
					}

					if (qsBranchName) {

						qsBranchName = qsBranchName.replace('+', ' ');

						for (i = 0; i < config.creditSense.branches.length; i++) {
							branch = config.creditSense.branches[i];
							branchName = branch.name;

							if (matchBranchNames(branchName, qsBranchName)) {
								matchingBranch = branch;
								break;
							}
						}
					}

					if (matchingBranch) {
						// A matching branch was found
						$scope.inputs.PreferredBranch.value = '' + matchingBranch.id;
						$scope.state.presetPreferredBranch = true;
					} else if (matchBranchNames(qsBranchName, config.SpecialBranches.Default)) {
						// The special "online" branch was specified
						$scope.inputs.PreferredBranch.value = config.SpecialBranches.Default;
						$scope.state.presetPreferredBranch = true;
					} else if (localStorageSupport) {
						// No value branch was set in the query string,
						// so check localStorage

						defaultPreferredBranch = localStorage.getItem(localStorageKeys.defaultPreferredBranch);

						if (config.creditSense && config.creditSense.branches) {
							validBranchIds = config.creditSense.branches.map(function (branch) {
								// Ensure it's a string for ease of comparison
								return '' + branch.id;
							});
							validBranchIds.push(config.SpecialBranches.Default);

							if (validBranchIds.indexOf(defaultPreferredBranch) !== -1) {
								$scope.inputs.PreferredBranch.value = defaultPreferredBranch;
								$scope.state.presetPreferredBranch = true;
							}
						}
					}
				}
			},
			location: {
				latLongDist: function (lat1, long1, lat2, long2) {
					// Haversine formula
					// See https://www.movable-type.co.uk/scripts/latlong.html

					var r = 6371; // Radius of Earth in kilometres

					lat1 = logic.location.toRadians(lat1);
					long1 = logic.location.toRadians(long1);

					lat2 = logic.location.toRadians(lat2);
					long2 = logic.location.toRadians(long2);

					var dLat = lat2 - lat1;
					var dLong = long2 - long1;

					var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
						Math.cos(lat1) * Math.cos(lat2) *
						Math.sin(dLong / 2) * Math.sin(dLong / 2);

					var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

					// Kilometres
					var d = r * c;

					return d;
				},

				toRadians: function (degrees) {
					var radians = degrees / 180 * Math.PI;

					return radians;
				}
			},
			formControl: {
				submitStepEvent: function (e, data, validationSummary) {
					e.preventDefault();

					logic.formControl._submitStep(data, validationSummary);
				},

				_submitStep: function (data, validationSummary, confirmationUrl) {
					if ($scope.conditions.isBusy()) {
						return;
					}

					// Convert date to string instead of letting JSON.stringify do it
					for (var key in data) {
						if (data[key] instanceof Date) {
							data[key] = logic.utils.encodeDate(data[key]);
						}
					}

					if (validationSummary.errors !== 0) {
						assist.speak('Could not submit the form. There ' +
							(validationSummary.errors > 1 ? 'were ' : 'was ') +
							validationSummary.errors +
							' error' +
							(validationSummary.errors > 1 ? 's' : '') +
							'.');

						Helpers.SCROLL_TO_FIRST_ERROR(validationSummary);

						return;
					}

					var current = $scope.state.step + 0;
					var token;
					if (config.data && config.data.Token) {
						token = config.data.Token;
					} else {
						token = $scope.state.appReference || '';
					}

					var guid;
					if (config.data && config.data.Guid) {
						guid = config.data.Guid;
					} else {
						guid = $scope.state.appGuid || '';
					}

					// Set any undefined values to null, so they are stored
					for (var field in data) {
						if (typeof data[field] === 'undefined') {
							data[field] = null;
						}
					}

					// Remove "preferredBranch" from submission if it
					// shouldn't show on any step, unless a default
					// other than the online branch was set
					// is logged in
					if (conditions.isLoggedOut() && !logic.preferredBranch.condition()) {
						if (!$scope.state.presetPreferredBranch) {
							data.PreferredBranch = '';
						} else if ($scope.inputs.PreferredBranch.value === config.SpecialBranches.Default) {
							data.PreferredBranch = '';
						}
					}

					// Convert address value into its parts,
					// for backwards compatability with before
					// the address component was created
					if (data.Address) {
						data.AddressUnit = data.Address.Unit;
						data.AddressStreetNumber = data.Address.StreetNumber;
						data.AddressStreetName = data.Address.StreetName;
						data.AddressStreetType = data.Address.StreetType;
						data.AddressSuburb = data.Address.Suburb;
						data.AddressCity = data.Address.City;
						data.AddressPostcode = data.Address.Postcode;
					}

					var formData = JSON.stringify(data);

					var submitStep = function (remainOnStep) {
						if (conditions.isBusy()) {
							return;
						}
						var stateCopy = JSON.parse(JSON.stringify($scope.state));
						stateCopy.step = current + 1;
						if (current === 4) {
							stateCopy.step = 4;
						}

						if ($scope.inputs.RelatedApplicationId.value !== null) {
							stateCopy.Source = 'Joint Borrower';
						} else if (conditions.isBroker()) {
							stateCopy.Source = 'Broker';
						} else if ($scope.inputs.PreferredBranch.value === config.SpecialBranches.Staff) {
							stateCopy.Source = 'Staff';
						} else if ($scope.state.presetPreferredBranch) {
							stateCopy.Source = 'Branch';
						} else {
							stateCopy.Source = 'Website';
						}

						var form = {
							Step: current,
							Token: token,
							Guid: guid || null,
							FormData: formData,
							FormState: JSON.stringify(stateCopy),
							CentrixResult: $scope.state.centrixResult || null,
							LastName: inputs.LastName.value,
							ObjectId: config.objectId,
							ReferrerDataToken: $scope.state.referrerDataToken || null
						};

						$scope.localState.busy = true;
						$scope.localState.isSubmitting = true;

						recaptcha.refreshToken()
							.then(function (recaptchaToken) {

								var postConfig = {
									method: 'POST',
									headers: {
										'Content-Type': 'application/json',
										'g-Recaptcha-Response': recaptchaToken
									},
									body: JSON.stringify(form)
								};

								// TODO IF-729 use fallback when present
								if ($('input[name="g-Recaptcha-Fallback"]').length) {
									postConfig.headers['g-Recaptcha-Fallback'] = $('input[name="g-Recaptcha-Fallback"]').val();
								}

								fetch(config.loanApplication.save, postConfig)
									.then(function (response) {
										if (response.status === 200) {
											return response;
										} else if (response.status === recaptchaFallbackRequiredStatusCode) {
											return response;
										} else {
											throw new Error('Failed to save form');
										}
									})
									.then(function (response) {
										if (response.status === recaptchaFallbackRequiredStatusCode) {
											$scope.localState.recaptchaFallbackRequired = true;
											$scope.localState.busy = false;
											$scope.localState.isSubmitting = false;
										} else {
											// Don't remove busy state because the next step is on another page
											// $scope.localState.busy = false;
											let data = response.json();

											if (response.status === 200) {
												if (window.hj && (window.hj instanceof Function)) {
													window.hj('formSubmitSuccessful');
												}

												$scope.state.appReference = data.Token;
												$scope.state.appGuid = data.Guid;
												$scope.state.centrixResult = data.CentrixResult;

												if (form.Complete) {
													$scope.state.complete = true;
												}

												if (remainOnStep === true) {
													$scope.localState.busy = false;
													$scope.localState.saveComplete = true;

													if (confirmationUrl) {
														logic.formControl._goToConfirmation(confirmationUrl);
													}
												} else {
													logic.formControl._setStep(current + 1);
												}
											} else {
												submitError(response);
											}
										}
									})
									.catch(submitError);
							})
							.catch(function (error) {
								// This error happened when we were trying to save the form
								publish(pubsubEvents.modelOpen, errorModalIds.save);
								console.error(error);
							});
					};

					var submitError = function (response) {
						if (window.hj && (window.hj instanceof Function)) {
							window.hj('formSubmitFailed');
						}

						$scope.localState.busy = false;

						if (response.status === 401) {
							window.removeEventListener('beforeunload', logic.formControl.leavePrompt);
							publish(pubsubEvents.modelOpen, errorModalIds.session);
						} else if (response.status === recaptchaFallbackRequiredStatusCode) {
							// TODO IF-729 require fallback when first recaptcha fails
							$scope.localState.recaptchaFallbackRequired = true;
						} else {
							publish(pubsubEvents.modelOpen, errorModalIds.save);
						}
					};

					switch (current) {
						case 1:
							if (localStorageSupport && localStorage.getItem(localStorageKeys.calculatorAmount)) {
								localStorage.removeItem(localStorageKeys.calculatorAmount);
							}

							// IF-585 Pre-save check
							if ($scope.state.preSaveCheckDone === false) {
								// Do pre-save check
								logic.preSaveCheck.doCheck(
									logic.preSaveCheck.handleResult(submitStep)
								);
							} else {
								submitStep();
							}
							break;
						case 2:
							submitStep();
							break;
						case 3:
							submitStep();
							break;
						case 4:
							submitStep(true);
							break;
					}
				},
				submitFormEvent: function () {
					return function (e, data, validationSummary) {
						//console.log({
						//	data,
						//	validationSummary,
						//	confirmation: config.loanApplication.confirmation
						//});
						logic.formControl._submitStep(data, validationSummary, config.loanApplication.confirmation);
					};
				},
				setStep: function (num) {
					//console.log({
					//	num: num,
					//	scopeStateStep: $scope.state.step,
					//	scopeStateComplete: $scope.state.complete,
					//	configDebug: config.debug
					//});

					if ($scope.conditions.isBusy()) {
						return;
					}

					// Can only step back, and only if the form is incomplete, unless in debug mode
					if ((num < $scope.state.step && !$scope.state.complete) || config.debug) {
						logic.formControl._setStep(num);
					}
				},
				_setStep: function (num) {
					var $appForm = $(selectors.appForm);
					if ($appForm.hasClass(classes.helpHint) === true) {
						$appForm.removeClass(classes.helpHint);
					}

					var stepUrl = config.loanApplication.steps[num - 1];
					var queryStringObj = qs.get();

					if (!queryStringObj.ref) {
						queryStringObj.ref = $scope.state.guid;
					}
					queryStringObj.amount = $scope.state.specifyLoanAmount
						? $scope.inputs.LoanAmountSpecific.value
						: $scope.inputs.LoanAmount.value;

					// Delete UTM parameters
					delete queryStringObj['utm_source'];
					delete queryStringObj['utm_medium'];
					delete queryStringObj['utm_campaign'];

					var queryString = qs.setString(queryStringObj);

					document.location = stepUrl + queryString;
				},
				leavePrompt: function (e) {
					var message = 'Are you sure you want to leave? Any unsaved changes will be lost.';

					// var $steps = $(selectors.appFormStep);
					// var $dirtyInputs = $steps.find('.ng-dirty:visible');

					var isDirty = isFormDirty; // $dirtyInputs.length > 0;

					if (isDirty && !$scope.localState.isSubmitting) {
						e.preventDefault();

						// Chrome requires this value to be set;
						e.returnValue = message;

						return message;
					}
				},
				_goToConfirmation: function (confirmationUrl) {
					if (conditions.isBusy()) {
						return;
					}

					// Maintain query string
					var query = qs.get();
					query.appref = config.data.Token;

					// Delete UTM parameters
					delete query['utm_source'];
					delete query['utm_medium'];
					delete query['utm_campaign'];

					var queryString = qs.setString(query);
					var url = confirmationUrl + queryString;

					$scope.localState.busy = true;
					document.location = url;
				},
				validateWithErrorLogging: function (e, callback, inputsToValidate) {
					try {
						$scope.actions.validate.apply(this, arguments);
					} catch (error) {
						console.error(error);
						publish(pubsubEvents.modelOpen, errorModalIds.save);
						logic.logging.logJavaScriptError(error);
					}
				},
				unlockStep: function (e, num) {
					if (e) {
						e.preventDefault();
					}
					$scope.state.stepLocked[num] = false;
				}
			},
			logging: {
				collectCheckSend: function () {
					var data = logic.logging._collect();
					var valid = logic.logging._check(data);

					if (!valid) {
						// Found at least one error
						logic.logging._send(data);
					}
				},
				_collect: function () {
					var loggingData = {
						step: $scope.state.step,
						userAgent: navigator.userAgent,
						objectId: config.objectId,
						validationSummary: {}
					};
					var prevStep;
					var stepInputs;

					var isStepInvalid = function (validationSummary) {
						var valid = true;
						var numErrors = validationSummary.errors;

						if (numErrors > 0) {
							valid = false;
						}

						return valid;
					};

					var buildValidationSummary = function (step) {
						return function (e, data, validationSummary) {
							loggingData.validationSummary[step] = validationSummary;
						};
					};

					for (prevStep = 1; prevStep < $scope.state.step; prevStep++) {
						stepInputs = $scope.stepInputs[prevStep];

						// Don't check PreferredBranch because its validation rules depend on the current step
						if (stepInputs.indexOf('PreferredBranch') !== -1) {
							stepInputs.splice(stepInputs.indexOf('PreferredBranch'), 1);
						}

						$scope.actions.validateWithErrorLogging(null, buildValidationSummary(prevStep), stepInputs);
					}

					// Reset error messages and live validation
					var inputName;
					var input;
					for (inputName in inputs) {
						input = inputs[inputName];
						input.error = null;
						input.liveValidateEnabled = false;
					}

					return loggingData;
				},
				_check: function (data) {
					var valid = true;

					var step;
					var stepData;

					for (step in data.validationSummary) {
						stepData = data.validationSummary[step];

						if (stepData.errors !== 0) {
							valid = false;
							break;
						}
					}

					return valid;
				},
				_send: function (data) {
					var loggingData = JSON.stringify(data);

					var $dataErrorLoggingForm = $(selectors.dataErrorLogging);
					var action = $dataErrorLoggingForm.attr('action');
					var formData = new FormData($dataErrorLoggingForm[0]);

					// Relying on ng-model for the correct values to
					// be in formData doesn't work, need to set manually

					if ($scope && $scope.state) {
						formData.set('LoggingToken', $scope.state.appReference);
					} else if (config && config.data) {
						formData.set('LoggingToken', config.data.Token);
					}
					formData.set('LoggingMessage', loggingData);

					$.ajax({
						url: action,

						type: 'POST',
						data: formData,
						processData: false,
						contentType: false,

						// success: logic.logging._postSuccess,
						// error: logic.logging._postError,
						complete: logic.logging._postComplete(data)
					});
				},

				// _postSuccess: function () {},
				// _postError: function () {},
				_postComplete: function (data) {
					// Send the user back to the first step with errors

					var firstStepWithErrors;

					var step;
					var stepData;

					for (step in data.validationSummary) {
						stepData = data.validationSummary[step];

						if (stepData.errors !== 0) {
							if (typeof firstStepWithErrors === 'undefined') {
								firstStepWithErrors = step;
							} else {
								firstStepWithErrors = Math.min(firstStepWithErrors, step);
							}
						}
					}

					if (typeof firstStepWithErrors !== 'undefined') {
						logic.logging._goToInvalidStep(firstStepWithErrors);
					}
				},

				_goToInvalidStep: function (step) {
					if (localStorageSupport) {
						localStorage.setItem(localStorageKeys.previousStepValidationError, true);
					}

					logic.formControl._setStep(step);
				},

				showDataError: function () {
					if (localStorageSupport) {
						if (localStorage.getItem(localStorageKeys.previousStepValidationError)) {
							localStorage.removeItem(localStorageKeys.previousStepValidationError);

							publish(pubsubEvents.modelOpen, errorModalIds.data);
						}
					}
				},

				logJavaScriptError: function (error) {
					var inputName;
					var $validationErrorLoggingForm = $(selectors.validationErrorLogging);
					var step;

					if ($scope && $scope.state) {
						step = $scope.state.step;
					}

					var data = {
						step: step,
						userAgent: navigator.userAgent,
						objectId: config.objectId,
						error: {
							message: error.message,
							name: error.name,
							stack: error.stack,

							// Firefox only
							fileName: error.fileName,
							lineNumber: error.lineNumber,
							columnNumber: error.columnNumber
						},
						formData: {}
					};

					// Gather form data, careful to catch
					// errors in case something in here is
					// the source of the detected JS error
					try {
						for (inputName in $scope.inputs) {
							data.formData[inputName] = $scope.inputs[inputName].value;
						}
					} catch (e) {
						data.formData = {
							message: 'Error gathering formData',
							error: {
								message: e.message,
								name: e.name,
								stack: e.stack,

								// Firefox only
								fileName: e.fileName,
								lineNumber: e.lineNumber,
								columnNumber: e.columnNumber
							}
						};
					}

					logic.logging._send(data);
				}
			},
			tooltipAttention: {
				// This section is for making tooltips "pulse" if the user appears to have paused on the form
				init: function () {
					logic.tooltipAttention._initEvents();
					logic.tooltipAttention._initSubscriptions();
				},

				_initEvents: function () {
					document.addEventListener('scroll', logic.tooltipAttention._scrollEvent);
					document.addEventListener('keydown', logic.tooltipAttention._keydownEvent);
					document.querySelector('body').addEventListener('focus', logic.tooltipAttention._focusEvent, true);
				},

				_initSubscriptions: function () {
					subscribe(pubsubEvents.tooltipOpened, logic.tooltipAttention._onTooltipOpened);
				},

				_scrollEvent: function (e) {
					logic.tooltipAttention._resetPause();
				},
				_focusEvent: function (e) {
					var $target = $(e.target);

					// Don't reset the pause if an element in a tooltip just got focus
					if ($target.closest(selectors.tooltip).length === 0) {
						// Use setTimeout to allow any active tooltips
						// to close before the check for open tooltips is done
						window.setTimeout(function () {
							logic.tooltipAttention._resetPause();
						},
							1);
					}
				},
				_keydownEvent: function (e) {
					logic.tooltipAttention._resetPause();
				},

				_resetPause: debounce(50,
					function () {
						logic.tooltipAttention._endPause();

						var tooltipOpen = logic.tooltipAttention._checkForOpenTooltips();
						if (tooltipOpen === false) {
							logic.tooltipAttention._startPause();
						}
					}),

				_endPause: function () {
					if (pauseTimeout) {
						window.clearTimeout(pauseTimeout);
						pauseTimeout = null;
					}

					if (pauseRepeatInterval) {
						window.clearInterval(pauseRepeatInterval);
						pauseRepeatInterval = null;
					}
				},

				_startPause: function () {
					if (pauseTimeout || pauseRepeatInterval) {
						logic.tooltipAttention._endPause();
					}

					pauseTimeout = window.setTimeout(logic.tooltipAttention._onPauseEnd, pauseDuration * 1000);
				},

				_onPauseEnd: function () {
					if (pauseRepeatAllowed) {
						pauseRepeatInterval = window.setInterval(logic.tooltipAttention._grabAttentionOnAllTooltips, pauseRepeatDuration * 1000);
					}

					logic.tooltipAttention._grabAttentionOnAllTooltips();
				},

				_onTooltipOpened: function () {
					if (pauseRepeatInterval) {
						pauseRepeatAllowed = false;
					}

					logic.tooltipAttention._endPause();
				},

				_checkForOpenTooltips: function () {
					// This behaviour needs to match tooltip.js
					var $tooltips = $(selectors.tooltip);

					var i;
					var $tooltip;
					var isOpen;
					for (i = 0; i < $tooltips.length; i++) {
						$tooltip = $tooltips.eq(i);

						isOpen = $tooltip.attr('aria-expanded') === 'true';

						if (isOpen) {
							return true;
						}
					}

					return false;
				},

				_grabAttentionOnAllTooltips: function () {
					publish(pubsubEvents.tooltipAttentionAll);
				},
			},
			preSaveCheck: {
				clear: function () {
					$scope.state.preSaveCheckDone = false;
				},

				doCheck: function (callback) {
					var request = new XMLHttpRequest();
					var $form = document.querySelector(selectors.appFormStep);

					recaptcha.refreshToken()
						.then(function () {
							var formData = new FormData($form);

							request.addEventListener('load',
								function () {
									if (callback) {
										// TODO IF-729 when recaptcha fails send fallback callback
										if (request.status === recaptchaFallbackRequiredStatusCode) {
											return callback('fallback');
										} else {
											return callback(request.response);
										}
									}
								});

							request.open('POST', api.preSaveCheck);
							request.send(formData);
						})
						.catch(function (error) {
							// This error happened when we were trying to save the form
							publish(pubsubEvents.modelOpen, errorModalIds.save);
						});
				},

				handleResult: function (callback) {
					var handleSuccess = function () {
						$scope.state.preSaveCheckDone = true;
						if (callback) {
							callback();
						}
					};

					return function (responseText) {
						try {
							// TODO IF-729 when fallback received set fallback state
							if (responseText === 'fallback') {
								$scope.localState.recaptchaFallbackRequired = true;
								// $scope.$apply();
							} else {
								var response = JSON.parse(responseText);
								if (response.success) {
									handleSuccess();
								} else {
									if (response.message === preSaveCheckErrorMessages.checkFailed) {
										publish(pubsubEvents.modelOpen, errorModalIds.preSaveCheck);
									} else {
										publish(pubsubEvents.modelOpen, errorModalIds.generic);
									}
								}
							}
						} catch (e) {
							publish(pubsubEvents.modelOpen, errorModalIds.generic);
						}

						// We don't need to worry about unbinding this event since
						// closing the modal means the user is sent to the homepage
						$(selectors.preSaveCheckModalContinue).on('click', handleSuccess);

						// Prevent the modal from being closed
						Keybinding.unbind('escape', modal.close);
					};
				}
			}
		};

		let actions = {
			getPhonePlaceholder: logic.phoneNumber.getPlaceholder,
			toggleSpecificLoanAmount: logic.loanAmount.toggleSpecific,
			submitStep: logic.formControl.submitStepEvent,
			submitForm: logic.formControl.submitFormEvent,
			setStep: logic.formControl.setStep,
			unlockStep: logic.formControl.unlockStep,

			validateWithErrorLogging: logic.formControl.validateWithErrorLogging,

			initRecaptchaFallback: function () {
				// TODO IF-729 when fallback element is visible init with Google

				let element = document.getElementById('recaptcha');

				if (initRecaptchaFallbackDone === false) {

					var setup = function () {

						element = document.getElementById('recaptcha');

						if (element !== null && initRecaptchaFallbackDone === false) {
							initRecaptchaFallbackDone = true;

							var options = {
								sitekey: config.recaptcha.fallback,
								callback: function (token) {

									var input = document.querySelector('input[name="g-Recaptcha-Fallback"]');
									input.value = token;

									$scope.localState.recaptchaFallbackCompleted = true;
								},
								'expired-callback': function () {
									$scope.localState.recaptchaFallbackCompleted = false;
								}
							};

							if (window.grecaptcha) {

								window.grecaptcha.ready(function () {
									grecaptcha.render('recaptcha', options);
								});

							}
						}
					};

					window.setInterval(setup, 100);
				}

				return true;
			}
		};

		let conditions = {
			hasValue: function (value) {
				var hasValue = true;

				// Some falsey values, like 0, are allowed
				if (typeof value === 'undefined') {
					hasValue = false;
				} else if (value === null) {
					hasValue = false;
				} else if (value === '') {
					hasValue = false;
				}

				return hasValue;
			},
			isBusy: function () {
				return $scope.localState.busy === true;
			},
			isComplete: function () {
				return $scope.state.complete === true;
			},
			isBusyOrComplete: function () {
				return conditions.isComplete() || conditions.isBusy();
			},
			isRecaptchaInitialised: function () {
				return $scope.localState.recaptchaInitialised;
			},
			canSubmitStep1: function () {
				var isBusy = conditions.isBusy();
				var isRecaptchaInitialised = conditions.isRecaptchaInitialised();

				var canSubmitStep1 = isRecaptchaInitialised && isBusy === false;

				// TODO IF-729
				var fallbackRequired = conditions.recaptchaFallbackRequired();
				var fallbackComplete = conditions.recaptchaFallbackCompleted();
				var fallbackDoneIfRequired = fallbackRequired === false || (fallbackRequired && fallbackComplete);

				return canSubmitStep1 && fallbackDoneIfRequired;
			},
			isEmployed: function () {
				var employmentStatus = $scope.inputs.EmploymentStatus.value || [];

				if (!Array.isArray(employmentStatus)) {
					employmentStatus = [employmentStatus];
				}

				return conditions.isEmployedByStatus(employmentStatus);
			},
			isPreviousEmployed: function () {
				var employmentStatus = $scope.inputs.PreviousEmploymentStatus.value || [];
				if (!Array.isArray(employmentStatus)) {
					employmentStatus = [employmentStatus];
				}

				return conditions.isEmployedByStatus(employmentStatus);
			},
			isEmployedByStatus: function (employmentStatus) {
				var fullTime = employmentStatus.indexOf('Full Time Worker') !== -1;
				var partTime = employmentStatus.indexOf('Part Time Worker') !== -1;
				var partTimeBeneficiary = employmentStatus.indexOf('Beneficiary + Part Time Worker') !== -1;
				var selfEmployed = employmentStatus.indexOf('Self Employed') !== -1;
				var casual = employmentStatus.indexOf('Casual') !== -1;

				var isEmployed = fullTime || partTime || partTimeBeneficiary || selfEmployed || casual;

				return isEmployed;
			},

			// Co-borrower
			isPrimaryApplication: function () {
				var isPrimaryApplication = $scope.state.secondaryApplication === false;

				return isPrimaryApplication;
			},
			isSecondaryApplication: function () {
				var isSecondaryApplication = conditions.isPrimaryApplication() === false;

				return isSecondaryApplication;
			},
			showJointApplicantFields: function () {
				// Needs to be the primary application, with "joint application" selected
				var isPrimaryApplication = conditions.isPrimaryApplication();
				var isJointApplication = $scope.state.jointApplication === true;
				var isBroker = conditions.isBroker();

				var showJointApplicantFields = isPrimaryApplication && isJointApplication && (isBroker === false);

				return showJointApplicantFields;
			},

			// Referrals
			isAmountLocked: function () {
				var isSecondaryApplication = conditions.isSecondaryApplication();

				var referrerName = $scope.inputs.Referrer.value;
				var referrer = $scope.state.referrer;
				var isLockedByReferrer;

				if (referrer) {
					isLockedByReferrer = referrer.lockAmount;
				}

				var isAmountLocked = !!(isSecondaryApplication || isLockedByReferrer);

				return isAmountLocked;
			},
			isBroker: function () {
				var referrer = $scope.state.referrer;
				var isBroker = false;

				if (referrer) {
					isBroker = !!referrer.isBroker;
				}

				return isBroker;
			},
			isIllionEnabled: function () {
				var illionDisabled = config.apiConfig.DisableIllion === true;

				return illionDisabled === false;
			},
			showAmount: function () {
				var isAmountLocked = conditions.isAmountLocked();

				var amountCondition = inputs.LoanAmount.condition();
				var amountSpecificCondition = inputs.LoanAmountSpecific.condition();

				var amountFieldConditions = amountCondition || amountSpecificCondition;

				var showAmount = amountFieldConditions && isAmountLocked === false;

				return showAmount;
			},
			isPurposeLocked: function () {
				var isSecondaryApplication = conditions.isSecondaryApplication();

				var referrerName = $scope.inputs.Referrer.value;
				var referrer = $scope.state.referrer;
				var isLockedByReferrer;

				if (referrer) {
					isLockedByReferrer = referrer.lockPurpose;
				}

				var isPurposeLocked = !!(isSecondaryApplication || isLockedByReferrer);

				return isPurposeLocked;
			},
			showPurpose: function () {
				var isPurposeLocked = conditions.isPurposeLocked();

				var purposeCondition = inputs.LoanPurpose.condition();

				var showPurpose = purposeCondition && isPurposeLocked === false;

				return showPurpose;
			},
			showPurposeOther: function () {
				var purposeIsActive = conditions.showPurpose();
				var purposeIsOther = $scope.inputs.LoanPurpose.value === 'Other';

				return purposeIsActive && purposeIsOther;
			},
			isReferreredBy: function (referrerName) {
				var isReferreredBy = referrerName === $scope.inputs.Referrer.value;

				return isReferreredBy;
			},

			// Logged in
			isLoggedIn: function () {
				var isLoggedIn = !!config.objectId;

				// If the form is in Broker mode, that takes precedence
				var isBroker = conditions.isBroker();

				return isLoggedIn && (isBroker === false);
			},
			isLoggedOut: function () {
				return conditions.isLoggedIn() === false;
			},
			isStepLocked: function (stepNumber) {
				return conditions.isLoggedIn() && $scope.state.stepLocked[stepNumber];
			},

			// IF-652
			requirePreviousAddressValue: function () {
				var hasCurrentValue = (!!$scope.inputs.AddressDurationMonth.value) && (!!$scope.inputs.AddressDurationYear.value);

				//console.log({
				//	hasCurrentValue: hasCurrentValue,
				//	AddressDurationMonth: inputs.AddressDurationMonth.value,
				//	AddressDurationYear: inputs.AddressDurationYear.value,
				//});

				if (hasCurrentValue == false) {
					return false;
				}

				var current = new Date(parseInt($scope.inputs.AddressDurationYear.value), parseInt($scope.inputs.AddressDurationMonth.value) - 1);
				var now = new Date();

				var diff = now.getMonth() - current.getMonth() + (12 * (now.getFullYear() - current.getFullYear()));
				var display = diff <= config.loanApplication.previousAddressMonthThreshold;

				//console.log({
				//	current: current,
				//	now: now,
				//	diff: diff,
				//	threshold: config.loanApplication.previousAddressMonthThreshold,
				//	display: display
				//});

				return display;
			},

			// IF-652
			requirePreviousEmploymentValue: function () {
				var hasCurrentValue = (!!$scope.inputs.EmploymentTimeMonth.value) && (!!$scope.inputs.EmploymentTimeYear.value);

				//console.log({
				//	hasCurrentValue: hasCurrentValue,
				//	EmploymentTimeMonth: inputs.EmploymentTimeMonth.value,
				//	EmploymentTimeYear: inputs.EmploymentTimeYear.value,
				//});

				if (hasCurrentValue == false) {
					return false;
				}

				var current = new Date(parseInt($scope.inputs.EmploymentTimeYear.value), parseInt($scope.inputs.EmploymentTimeMonth.value) - 1);
				var now = new Date();

				var diff = now.getMonth() - current.getMonth() + (12 * (now.getFullYear() - current.getFullYear()));
				var display = diff <= config.loanApplication.previousEmploymentMonthThreshold;

				//console.log({
				//	current: current,
				//	now: now,
				//	diff: diff,
				//	threshold: config.loanApplication.previousEmploymentMonthThreshold,
				//	display: display
				//});

				return display;
			},

			showFieldOrSummary: {
				LoanAmount: function () {
					var isBroker = conditions.isBroker();

					var showLoanAmount = isBroker === false;

					return showLoanAmount;
				},
				LoanAmountSpecific: function () {
					var isBroker = conditions.isBroker();
					var specifyLoanAmount = $scope.state.specifyLoanAmount;

					var showLoanAmountSpecific = specifyLoanAmount || isBroker;

					return showLoanAmountSpecific;
				},
				BrokerFinancialStructure: function () {
					var isBroker = conditions.isBroker();
					var isPrimary = conditions.isPrimaryApplication();

					var showBrokerFinancialStructure = isBroker && isPrimary;

					return showBrokerFinancialStructure;
				},

				VisaType: function () {
					return $scope.inputs.Residency.value === 'No';
				},
				VisaExpiry: function () {
					return conditions.showFieldOrSummary.VisaType();
				},

				DriverLicenceNumber: function () {
					return $scope.inputs.ProofType.value === 'NZDL';
				},
				DriverLicenceVersion: function () {
					return conditions.showFieldOrSummary.DriverLicenceNumber();
				},
				DriverLicenceIdUpload: function () {
					var isDriverLicence = conditions.showFieldOrSummary.DriverLicenceNumber();
					var isBroker = conditions.isBroker();

					return isDriverLicence && isBroker;
				},
				PassportNumber: function () {
					return $scope.inputs.ProofType.value === 'PASS';
				},
				PassportExpiry: function () {
					return conditions.showFieldOrSummary.PassportNumber();
				},
				PassportCountry: function () {
					var showPassportFields = conditions.showFieldOrSummary.PassportNumber();
					var internationalPassportsDisabled = config.loanApplication.disablePassportCountry;

					return showPassportFields === true && internationalPassportsDisabled === false;
				},
				PassportIdUpload: function () {
					var isPassport = conditions.showFieldOrSummary.PassportNumber();
					var isBroker = conditions.isBroker();

					return isPassport && isBroker;
				},
				SecurityTypeVehicle: function () {
					return $scope.inputs.SecurityType.value === 'Vehicle';
				},
				PreferredBranch: logic.preferredBranch.condition,
			},

			isDataCompleteStep2: function () {
				// This value is cached when the form is loaded, based on imported data only
				return !step2MissingData;
			},

			showAddButtonStep2: function () {
				return conditions.isStepLocked(2) && !conditions.isDataCompleteStep2();
			},

			showChangeButtonStep2: function () {
				return conditions.isStepLocked(2) && conditions.isDataCompleteStep2();
			},

			disableConfirmButtonStep2: function () {
				// TODO IF-729
				var stepIsInvalid = conditions.isStepLocked(2) && !conditions.isDataCompleteStep2();

				var fallbackRequired = conditions.recaptchaFallbackRequired();
				var fallbackComplete = conditions.recaptchaFallbackCompleted();
				var fallbackDoneIfRequired = fallbackRequired === false || (fallbackRequired && fallbackComplete);

				return stepIsInvalid || fallbackDoneIfRequired === false;
			},


			isDataCompleteStep3: function () {
				// This value is cached when the form is loaded, based on imported data only
				return !step3MissingData;
			},

			showAddButtonStep3: function () {
				return conditions.isStepLocked(3) && !conditions.isDataCompleteStep3();
			},

			showChangeButtonStep3: function () {
				return conditions.isStepLocked(3) && conditions.isDataCompleteStep3();
			},

			disableConfirmButtonStep3: function () {
				// TODO IF-729
				var stepIsInvalid = conditions.isStepLocked(3) && !conditions.isDataCompleteStep3();

				var fallbackRequired = conditions.recaptchaFallbackRequired();
				var fallbackComplete = conditions.recaptchaFallbackCompleted();
				var fallbackDoneIfRequired = fallbackRequired === false || (fallbackRequired && fallbackComplete);

				return stepIsInvalid || fallbackDoneIfRequired === false;
			},


			hasSecurityData: function () {
				return !!$scope.inputs.SecurityType.value;
			},

			hasEmploymentData: function () {
				return !!$scope.inputs.EmploymentStatus.value;
			},

			willDoIllionLater: function () {
				var illionSwitchVisible = inputs.BrokerIllionSwitch.condition();
				var illionSwitchLater = $scope.inputs.BrokerIllionSwitch.value === 'Later';

				var willDoIllionLater = illionSwitchVisible && illionSwitchLater;

				return willDoIllionLater;
			},

			canSubmitApplication: function () {
				return conditions.hasSecurityData() && conditions.hasEmploymentData();
			},

			// TODO IF-729 check state of fallback
			recaptchaFallbackRequired: function () {
				var isFallbackRequired = $scope.localState.recaptchaFallbackRequired;
				return isFallbackRequired;
			},

			// TODO IF-729 check state of fallback
			recaptchaFallbackCompleted: function () {
				var isCompleted = $scope.localState.recaptchaFallbackCompleted;
				return isCompleted;
			},
		};

		let createDollarValues = function (el) {
			var obj = {
				displayValue: '$' + el.toLocaleString('en-nz'),
				value: el
			};

			return obj;
		};

		let disabledIfBusy = function () {
			return conditions.isBusy();
		};
		let disabledIfBusyOrSecondary = function () {
			return conditions.isBusy() || $scope.state.secondaryApplication;
		};

		let jointApplicantName = function () {
			return {
				condition: conditions.showJointApplicantFields,
				validation: {
					required: true,
					pattern: Patterns.NAME,
					maxlength: 42
				},
				update: logic.preSaveCheck.clear,
				disabled: disabledIfBusy,
				step: 1,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Our system does not support digits or special characters in your name',
				}
			};
		};

		let validation = function (req, regex, length) {
			return {
				required: req,
				pattern: regex,
				maxlength: length
			}
		};

		let inputs = {
			Referrer: {
				// Referrer doesn't appear in the view, and
				// has no validation rules, so doesn't need
				// to be associated with any given step
				value: null
			},
			ReferrerData: {
				// ReferrerData doesn't appear in the view, and
				// has no validation rules, so doesn't need
				// to be associated with any given step
				value: null
			},

			// Step 1
			BrokerEmailSubmission: {
				validation: validation(true, Patterns.EMAIL, 100),
				condition: conditions.isBroker,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				type: 'email',
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please enter a valid email address',
				}
			},
			LoanAmount: {
				values: config.loanApplication.amountRanges.map(createDollarValues),
				condition: conditions.showFieldOrSummary.LoanAmount,
				disabled: function () {
					var isDisabled = $scope.state.specifyLoanAmount ||
						disabledIfBusy() ||
						$scope.state.secondaryApplication;
					return isDisabled;
				},
				update: logic.preSaveCheck.clear,
				init: logic.loanAmount.forget,
				step: 1,
				value: 5000,
				label: 'Amount',
			},
			LoanAmountSpecific: {
				validation: {
					required: true,
					custom: logic.utils.assertInteger,
					min: config.inputs.LoanAmountSpecific.min,
					max: config.inputs.LoanAmountSpecific.max
				},
				condition: conditions.showFieldOrSummary.LoanAmountSpecific,
				disabled: disabledIfBusyOrSecondary,
				update: function () {
					logic.loanAmount.forget();
					logic.preSaveCheck.clear();
				},
				//init: logic.referrer.setLoanDetails,
				step: 1,
				label: 'Amount',
				errors: {
					errorRequired: 'This field is required',
					errorMin: `Please enter a whole dollar amount between $${config.inputs.LoanAmountSpecific.min} and $${config.inputs.LoanAmountSpecific.max}`,
					errorMax: `Please enter a whole dollar amount between $${config.inputs.LoanAmountSpecific.min} and $${config.inputs.LoanAmountSpecific.max}`,
					errorCustom: `Please enter a whole dollar amount between $${config.inputs.LoanAmountSpecific.min} and $${config.inputs.LoanAmountSpecific.max}`,
				},
			},
			LoanAmountDescription: {
				// LoanAmountDescription only appears in the view when
				// it's read only. It has no validation rules, so
				// doesn't need to be associated with any given step
				value: null
			},
			BrokerRefinance: {
				validation: {
					required: true,
					custom: logic.utils.assertInteger,
				},
				condition: conditions.showFieldOrSummary.BrokerFinancialStructure,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				value: 0,
				min: 0,
				errors: {
					errorRequired: 'Please enter a whole dollar amount',
					errorMin: 'Please enter a whole dollar amount',
					errorCustom: 'Please enter a whole dollar amount'
				}
			},
			BrokerCashDeposit: {
				validation: {
					required: true,
					custom: logic.utils.assertInteger,
				},
				condition: conditions.showFieldOrSummary.BrokerFinancialStructure,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				value: 0,
				min: 0,
				errors: {
					errorRequired: 'Please enter a whole dollar amount',
					errorMin: 'Please enter a whole dollar amount',
					errorCustom: 'Please enter a whole dollar amount'
				}
			},
			BrokerTradeIn: {
				validation: {
					required: true,
					custom: logic.utils.assertInteger,
				},
				condition: conditions.showFieldOrSummary.BrokerFinancialStructure,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				value: 0,
				min: 0,
				errors: {
					errorRequired: 'Please enter a whole dollar amount',
					errorMin: 'Please enter a whole dollar amount',
					errorCustom: 'Please enter a whole dollar amount'
				}
			},
			BrokerFee: {
				validation: {
					required: true,
					custom: logic.utils.assertInteger,
				},
				condition: conditions.showFieldOrSummary.BrokerFinancialStructure,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				value: 0,
				min: 0,
				errors: {
					errorRequired: 'Please enter a whole dollar amount',
					errorMin: 'Please enter a whole dollar amount',
					errorCustom: 'Please enter a whole dollar amount'
				}
			},
			BrokerOptInCci: {
				validation: Rules.REQUIRED,
				condition: conditions.showFieldOrSummary.BrokerFinancialStructure,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				values: ['Yes', 'No'],
				errors: {
					errorRequired: 'This field is required',
				}
			},
			BrokerCciLpi: {
				validation: {
					required: true,
					custom: logic.utils.assertInteger,
				},
				condition: function () {
					var showBrokerFinancialStructure = conditions.showFieldOrSummary.BrokerFinancialStructure();
					var hasNoInsurance = $scope.inputs.BrokerOptInCci.value === 'No';

					return showBrokerFinancialStructure && hasNoInsurance;
				},
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				value: 0,
				min: 0,
				errors: {
					errorRequired: 'Please enter a whole dollar amount',
					errorMin: 'Please enter a whole dollar amount',
					errorCustom: 'Please enter a whole dollar amount'
				}
			},
			BrokerMbi: {
				validation: {
					required: true,
					custom: logic.utils.assertInteger,
				},
				condition: conditions.showFieldOrSummary.BrokerFinancialStructure,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				value: 0,
				min: 0,
				errors: {
					errorRequired: 'Please enter a whole dollar amount',
					errorMin: 'Please enter a whole dollar amount',
					errorCustom: 'Please enter a whole dollar amount'
				}
			},
			BrokerGap: {
				validation: {
					required: true,
					custom: logic.utils.assertInteger,
				},
				condition: conditions.showFieldOrSummary.BrokerFinancialStructure,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				value: 0,
				min: 0,
				errors: {
					errorRequired: 'Please enter a whole dollar amount',
					errorMin: 'Please enter a whole dollar amount',
					errorCustom: 'Please enter a whole dollar amount'
				}
			},
			LoanPurpose: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return conditions.isPrimaryApplication();
				},
				disabled: disabledIfBusyOrSecondary,
				update: logic.preSaveCheck.clear,
				step: 1,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Only New Zealand letters allowed',
				}
			},
			LoanPurposeOther: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return conditions.showPurposeOther();
				},
				disabled: disabledIfBusyOrSecondary,
				step: 1,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Only New Zealand letters allowed',
				}
			},
			JointApplication: {
				validation: Rules.REQUIRED,
				disabled: disabledIfBusyOrSecondary,
				step: 1,
				update: function () {
					$scope.state.jointApplication = $scope.inputs.JointApplication.value === 'Yes';
					logic.preSaveCheck.clear();
				},
				values: ['Yes', 'No'],
				errors: {
					errorRequired: 'This field is required',
				}
			},
			JointApplicantFirstName: jointApplicantName(),
			JointApplicantLastName: jointApplicantName(),
			JointApplicantEmail: {
				validation: validation(true, Patterns.EMAIL, 255),
				condition: conditions.showJointApplicantFields,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				type: 'email',
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please enter a valid email address',
				}
			},
			JointApplicantPhoneNumber: {
				validation: validation(true, Patterns.PHONE, 100),
				condition: conditions.showJointApplicantFields,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				type: 'tel',
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please enter a valid phone number',
				}
			},
			Title: {
				validation: validation(true, Patterns.ALPHA, 100),
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				errors: {
					errorRequired: 'This field is required',
				}
			},
			FirstName: {
				validation: validation(true, Patterns.NAME, 100),
				condition: conditions.isLoggedOut,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Our system does not support digits or special characters in your name',
				}
			},
			MiddleName: {
				validation: validation(false, Patterns.NAME, 100),
				condition: conditions.isLoggedOut,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Our system does not support digits or special characters in your name',
				}
			},
			LastName: {
				validation: validation(true, Patterns.NAME, 100),
				condition: conditions.isLoggedOut,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Our system does not support digits or special characters in your name',
				}
			},
			Email: {
				validation: validation(true, Patterns.EMAIL, 255),
				condition: conditions.isLoggedOut,
				disabled: disabledIfBusy,
				update: logic.preSaveCheck.clear,
				step: 1,
				type: 'email',
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please enter a valid email address',
				}
			},
			AgreedToPrivacyPolicy: {
				validation: Rules.REQUIRED,
				disabled: disabledIfBusy,
				step: 1,
				update: function () {
					logic.consent.rememberPrivacyPolicyVersion();
					logic.preSaveCheck.clear();
				},
				errors: {
					errorRequired: ''
				},
				init: logic.consent.rememberPrivacyPolicyVersion
			},
			PrivacyPolicyVersion: {
				validation: validation(false, Patterns.VERSION, 100),
				value: null
			},
			AgreedToElectronicCommunication: {
				disabled: disabledIfBusy,
				step: 1,
				update: function () {
					logic.consent.rememberMarketingPolicyVersion();
					logic.preSaveCheck.clear();
				},
				init: logic.consent.rememberMarketingPolicyVersion
			},
			MarketingPolicyVersion: {
				validation: validation(false, Patterns.VERSION, 100),
				value: null
			},
			RelatedApplicationId: {
				// RelatedApplicationId doesn't appear in the view, and
				// has no validation rules, so doesn't need
				// to be associated with any given step
				value: null
			},
			BrokerAccuracyConfirmation: {
				validation: Rules.REQUIRED,
				condition: conditions.isBroker,
				disabled: disabledIfBusy,
				step: 1,
			},
			DateOfBirth: {
				validation: {
					required: true,
					min: minAge,
					max: maxAge
				},
				condition: conditions.isLoggedOut,
				disabled: disabledIfBusy,
				step: 2,
				errors: {
					errorRequired: 'Please enter your date of birth',
					errorInvalid: 'Please enter a valid date',
					errorMin: 'You must be at least 18 years old'
				}
			},
			Gender: {
				validation: validation(true, Patterns.ALPHA, 20),
				condition: conditions.isLoggedOut,
				disabled: disabledIfBusy,
				step: 2,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			ContactNumberType: {
				validation: validation(true, Patterns.ALPHA, 6),
				condition: conditions.isLoggedOut,
				disabled: disabledIfBusy,
				step: 2,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			ContactNumber: {
				validation: validation(true, Patterns.PHONE, 100),
				condition: conditions.isLoggedOut,
				disabled: disabledIfBusy,
				step: 2,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please enter a valid phone number'
				},
				type: 'tel'
			},
			RelationshipStatus: {
				validation: validation(true, Patterns.ALPHA_SPACES, 50),
				condition: function () {
					return $scope.conditions.isStepLocked(2) === false;
				},
				disabled: disabledIfBusy,
				step: 2,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			FinancialDependants: {
				validation: validation(true, Patterns.FIN_DEPS, 5),
				condition: function () {
					return $scope.conditions.isStepLocked(2) === false;
				},
				disabled: disabledIfBusy,
				step: 2,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			Address: {
				validation: Rules.REQUIRED,
				condition: function () {
					return $scope.conditions.isStepLocked(2) === false;
				},
				disabled: disabledIfBusy,
				step: 2,
				labelUnit: config.inputs.Address.content.labelunit,
				labelStreetNumber: config.inputs.Address.content.labelstreetnumber,
				labelStreetName: config.inputs.Address.content.labelstreetname,
				labelStreetType: config.inputs.Address.content.labelstreettype,
				labelSuburb: config.inputs.Address.content.labelsuburb,
				labelCity: config.inputs.Address.content.labelcity,
				labelPostcode: config.inputs.Address.content.labelpostcode,
				placeholderUnit: config.inputs.Address.content.placeholderunit,
				placeholderStreetNumber: config.inputs.Address.content.placeholderstreetnumber,
				placeholderStreetName: config.inputs.Address.content.placeholderstreetname,
				placeholderStreetType: config.inputs.Address.content.placeholderstreettype,
				placeholderSuburb: config.inputs.Address.content.placeholderSuburb,
				placeholderCity: config.inputs.Address.content.placeholdercity,
				placeholderPostcode: config.inputs.Address.content.placeholderpostcode,
				searchUnavailable: 'Search unavailable',
				errors: {
					errorRequired: 'This field is required',
					errorRequiredPart: 'This field is required',
					errorInvalid: 'Please enter a complete address',
					errorInvalidPart: 'This field is required'
				},
				value: {
					BoxType: null,
					Unit: null,
					StreetNumber: null,
					StreetName: null,
					StreetType: null,
					Suburb: null,
					City: null,
					Postcode: null
				}
			},
			AddressStatus: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return $scope.conditions.isStepLocked(2) === false;
				},
				disabled: disabledIfBusy,
				step: 2,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			// These inputs are co-required, so use a custom validation
			// function to check that they are either both empty or both
			// have a value, and use a custom update function to ensure they
			// will trigger one another's live validation.
			AddressDurationMonth: {
				validation: validation(true, Patterns.MONTH, 2),
				condition: function () {
					return $scope.conditions.isStepLocked(2) === false;
				},
				disabled: disabledIfBusy,
				step: 2,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			AddressDurationYear: {
				validation: validation(true, Patterns.YEAR, 4),
				condition: function () {
					return $scope.conditions.isStepLocked(2) === false;
				},
				disabled: disabledIfBusy,
				step: 2,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			PreviousAddressMonth: {
				validation: validation(true, Patterns.MONTH, 2),
				condition: function () {
					var isRequired = conditions.requirePreviousAddressValue();
					var step2isUnlocked = $scope.conditions.isStepLocked(2) === false;

					return isRequired && step2isUnlocked;
				},
				disabled: disabledIfBusy,
				step: 2
			},
			PreviousAddressYear: {
				validation: validation(true, Patterns.YEAR, 4),
				condition: function () {
					var isRequired = conditions.requirePreviousAddressValue();
					var step2isUnlocked = $scope.conditions.isStepLocked(2) === false;

					return isRequired && step2isUnlocked;
				},
				disabled: disabledIfBusy,
				step: 2
			},
			ExistingCustomer: {
				validation: validation(true, Patterns.ALPHA, 5),
				condition: function () {
					var hasBranches = config.creditSense.branches.length > 0;
					var isLoggedOut = conditions.isLoggedOut();
					var isNotBroker = conditions.isBroker() === false;
					var hasNoPresetBranch = $scope.state.presetPreferredBranch === false;

					return hasBranches && isLoggedOut && isNotBroker && hasNoPresetBranch;
				},
				value: null,
				values: ['Yes', 'No'],
				step: 2,
				errors: {
					errorRequired: 'Please specify if your are an existing customer'
				}
			},
			PreferredBranch: {
				validation: Rules.REQUIRED,
				condition: function () {
					return logic.preferredBranch.condition();
				},
				disabled: disabledIfBusy,
				value: null,
				values: logic.preferredBranch.getValues(config.creditSense.branches),
				step: 2,
				errors: {
					errorRequired: 'You need to select a branch'
				}
			},
			// Step 3
			Residency: {
				validation: validation(true, Patterns.ALPHA, 5),
				disabled: disabledIfBusy,
				values: ['Yes', 'No'],
				step: 3,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			VisaType: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return conditions.showFieldOrSummary.VisaType() && $scope.conditions.isStepLocked(3) === false;
				},
				step: 3,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			VisaExpiry: {
				validation: {
					required: true,
					min: logic.utils.getTodayString()
				},
				condition: function () {
					return conditions.showFieldOrSummary.VisaExpiry() && $scope.conditions.isStepLocked(3) === false;
				},
				step: 3,
				errors: {
					errorRequired: 'This field is required',
					errorMin: 'You must have a current visa'
				}
			},
			ProofType: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return $scope.conditions.isStepLocked(3) === false;
				},
				disabled: disabledIfBusy,
				step: 3,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			DriverLicenceNumber: {
				validation: {
					required: true,
					pattern: Patterns.ALPHA_NUM,
					maxlength: 8
				},
				condition: function () {
					return conditions.showFieldOrSummary.DriverLicenceNumber() && $scope.conditions.isStepLocked(3) === false;
				},
				disabled: disabledIfBusy,
				step: 3,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Your Driver Licence Number should only contain letters and numbers'
				}
			},
			DriverLicenceVersion: {
				validation: {
					required: true,
					pattern: Patterns.NUM,
					maxlength: 3
				},
				condition: function () {
					return conditions.showFieldOrSummary.DriverLicenceVersion() && $scope.conditions.isStepLocked(3) === false;
				},
				disabled: disabledIfBusy,
				step: 3,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Your Driver Licence Version should only contain numbers'
				}
			},
			DriverLicenceIdUpload: {
				placeholder: 'No file selected',
				condition: conditions.showFieldOrSummary.DriverLicenceIdUpload,
				disabled: disabledIfBusy,
				buttonText: 'Select a file',
				uploadApi: config.inputs.DriverLicenceIdUpload.content.uploadapi,
				allowedFormats: 'jpg,jpeg,png,gif,pdf',
				maxSizeMb: '5',
				step: 3,
				errors: {
					errorRequired: 'Please upload a file.',
					errorIncomplete: 'Please upload your selected file.',
					errorFileFormat: 'Allowed formats are jpg, jpeg, png, gif, and pdf.',
					errorFileSize: 'The file you have selected is too large. Please select a file smaller than 5 MB.',
					errorUpload: 'Something went wrong, please try again.'
				}
			},
			PassportNumber: {
				validation: {
					required: true,
					pattern: Patterns.ALPHA_NUM,
					maxlength: 9
				},
				condition: function () {
					return conditions.showFieldOrSummary.PassportNumber() && $scope.conditions.isStepLocked(3) === false;
				},
				disabled: disabledIfBusy,
				step: 3,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			PassportExpiry: {
				validation: {
					required: true
				},
				condition: function () {
					return conditions.showFieldOrSummary.PassportExpiry() && $scope.conditions.isStepLocked(3) === false;
				},
				disabled: disabledIfBusy,
				step: 3,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			PassportCountry: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return conditions.showFieldOrSummary.PassportCountry() && $scope.conditions.isStepLocked(3) === false;
				},
				value: config.loanApplication.disablePassportCountry ? 'NZ' : null, // IF-621 - set to NZ value when disabled in CMS
				disabled: disabledIfBusy,
				step: 3,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			PassportIdUpload: {
				placeholder: 'No file selected',
				condition: conditions.showFieldOrSummary.PassportIdUpload,
				disabled: disabledIfBusy,
				buttonText: 'Select a file',
				uploadApi: config.inputs.PassportIdUpload.content.uploadapi,
				allowedFormats: 'jpg,jpeg,png,gif,pdf',
				maxSizeMb: '5',
				step: 3,
				errors: {
					errorRequired: 'Please upload a file.',
					errorIncomplete: 'Please upload your selected file.',
					errorFileFormat: 'Allowed formats are jpg, jpeg, png, gif, and pdf.',
					errorFileSize: 'The file you have selected is too large. Please select a file smaller than 5 MB.',
					errorUpload: 'Something went wrong, please try again.'
				}
			},

			// Step 4
			SecurityType: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return $scope.conditions.isStepLocked('4-security') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			VehicleRegistrationNumber: {
				validation: {
					maxlength: 6,
					pattern: Patterns.ALPHA_NUM,
					custom: function (value) {
						if (conditions.isBroker()) {
							// When in broker mode, this input is co-required with VehicleVinNumber
							if (!value && !$scope.inputs.VehicleVinNumber.value) {
								// This input has no custom error message, it shows beneath the VIN input instead
								return ErrorTypes.CUSTOM;
							}
						} else if (!value) {
							return ErrorTypes.REQUIRED;
						}

						return true;
					},
				},
				hasCustomRequiredValidation: true,
				update: function () {
					inputs.VehicleVinNumber.liveValidate();
				},
				condition: conditions.showFieldOrSummary.SecurityTypeVehicle,
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Only letters and numbers allowed'
				}
			},
			VehicleVinNumber: {
				validation: {
					custom: function (value) {
						// Co-required with VehicleRegistrationNumber
						if (!value && !$scope.inputs.VehicleRegistrationNumber.value) {
							return ErrorTypes.REQUIRED;
						}

						return true;
					},
				},
				hasCustomRequiredValidation: true,
				update: function () {
					inputs.VehicleRegistrationNumber.liveValidate();
				},
				condition: function () {
					var isVehicleSecurity = conditions.showFieldOrSummary.SecurityTypeVehicle();
					var isBroker = conditions.isBroker();

					return isVehicleSecurity && isBroker;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'Please provide a vehicle licence plate number or a VIN'
				}
			},
			EmploymentStatus: {
				validation: validation(true, Patterns.EMP_STATUS, 100),
				condition: function () {
					return $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			EmploymentIndustry: {
				validation: validation(true, Patterns.ALPHA_SPACES, 50),
				condition: function () {
					return conditions.isEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			EmploymentJobRole: {
				validation: validation(true, Patterns.ALPHA_SPACES, 50),
				condition: function () {
					return conditions.isEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Only New Zealand letters and spaces allowed'
				}
			},
			EmploymentCompany: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return conditions.isEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Only New Zealand letters and spaces allowed'
				}
			},
			EmploymentArea: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return conditions.isEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Only New Zealand letters allowed'
				}
			},
			EmploymentWorkPhoneNumber: {
				validation: validation(true, Patterns.PHONE, 100),
				condition: function () {
					return conditions.isEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please remove invalid characters'
				}
			},
			EmploymentTimeMonth: {
				validation: validation(true, Patterns.MONTH, 2),
				condition: function () {
					return conditions.isEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4
			},
			EmploymentTimeYear: {
				validation: validation(true, Patterns.YEAR, 4),
				condition: function () {
					return conditions.isEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4
			},
			// IF-652 - Previous Employment
			PreviousEmploymentStatus: {
				validation: validation(true, Patterns.EMP_STATUS, 100),
				condition: function () {
					var isRequired = conditions.requirePreviousEmploymentValue();
					var step4isUnlocked = $scope.conditions.isStepLocked('4-employment') === false;

					return isRequired && step4isUnlocked;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			PreviousEmploymentIndustry: {
				validation: validation(true, Patterns.ALPHA_SPACES, 50),
				condition: function () {
					return conditions.isPreviousEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			PreviousEmploymentJobRole: {
				validation: validation(true, Patterns.ALPHA_SPACES, 50),
				condition: function () {
					return conditions.isPreviousEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Only New Zealand letters and spaces allowed'
				}
			},
			PreviousEmploymentCompany: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return conditions.isPreviousEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Only New Zealand letters and spaces allowed'
				}
			},
			PreviousEmploymentArea: {
				validation: validation(true, Patterns.ALPHA_SPACES, 100),
				condition: function () {
					return conditions.isPreviousEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Only New Zealand letters allowed'
				}
			},
			PreviousEmploymentWorkPhoneNumber: {
				validation: validation(true, Patterns.PHONE, 100),
				condition: function () {
					return conditions.isPreviousEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Please remove invalid characters'
				}
			},
			PreviousEmploymentTimeMonth: {
				validation: validation(true, Patterns.MONTH, 2),
				condition: function () {
					return conditions.isPreviousEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4
			},
			PreviousEmploymentTimeYear: {
				validation: validation(true, Patterns.YEAR, 4),
				condition: function () {
					return conditions.isPreviousEmployed() && $scope.conditions.isStepLocked('4-employment') === false;
				},
				disabled: disabledIfBusy,
				step: 4
			},
			ImpendingIncomeChange: {
				validation: validation(true, Patterns.ALPHA, 5),
				disabled: disabledIfBusy,
				step: 4,
				values: ['Yes', 'No'],
				errors: {
					errorRequired: 'This field is required'
				}
			},
			IncomeChangeReason: {
				validation: validation(true, Patterns.FREE_TEXT, 250),
				condition: function () {
					return $scope.inputs.ImpendingIncomeChange.value === 'Yes';
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required',
					errorPattern: 'Our system does not support all special characters'
				}
			},
			BrokerIllionSwitch: {
				validation: Rules.REQUIRED,
				condition: function () {
					var isBroker = conditions.isBroker();
					var illionEnabled = conditions.isIllionEnabled();

					return isBroker && illionEnabled;
				},
				disabled: function () {
					var isBusy = disabledIfBusy();
					var iframeStarted = !!($scope.inputs.BrokerIllionIframe.value && $scope.inputs.BrokerIllionIframe.value.length);
					var hasSubmissionId = !!$scope.inputs.BrokerIllionDocumentId.value;

					var isDisabled = isBusy || iframeStarted || hasSubmissionId;

					return isDisabled;
				},
				step: 4,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			BrokerIllionIframe: {
				validation: Rules.REQUIRED,
				condition: function () {
					var isBroker = conditions.isBroker();
					var illionEnabled = conditions.isIllionEnabled();
					var illionSwitchIframe = $scope.inputs.BrokerIllionSwitch.value === 'No';

					return isBroker && illionEnabled && illionSwitchIframe;
				},
				disabled: disabledIfBusy,
				step: 4,
				successMessageHtml: config.inputs.BrokerIllionIframe.content.successmessage,
				appReference: config.data?.Token,
				errors: {
					errorRequired: 'Please submit a bank statement'
				}
			},
			BrokerIllionDocumentId: {
				validation: Rules.REQUIRED,
				condition: function () {
					var isBroker = conditions.isBroker();
					var illionEnabled = conditions.isIllionEnabled();
					var illionSwitchSubmissionId = $scope.inputs.BrokerIllionSwitch.value === 'Yes';

					return isBroker && illionEnabled && illionSwitchSubmissionId;
				},
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorRequired: 'This field is required'
				}
			},
			AdditionalInformation: {
				validation: validation(false, Patterns.FREE_TEXT, 250),
				disabled: disabledIfBusy,
				step: 4,
				errors: {
					errorPattern: 'Our system does not support all special characters'
				}
			}
		};

		if (config) {
			inputs = Teraform.mergeFieldConfig(config.inputs, inputs);
		}
		inputs = Teraform.initInputs(inputs);
		// console.log({ config, inputs });

		var importData = function () {
			var data;
			var state;

			var inputName;
			var loanAmount;

			var step;

			var findMatchingValue = function (inputName) {
				// Look up the value in the data in the values and displayValue
				// configured for the input. In some cases, the value sent by
				// the server will match the displayValue, not the value, so
				// it will need to be converted.

				var inputOptions = config.loanApplication.inputOptions[inputName];
				var dataValue = data[inputName];
				var value;

				var valueMatch = inputOptions.filter(function (option) {
					return option.value === dataValue;
				});
				valueMatch = valueMatch.length ? valueMatch[0] : null;

				var displayValueMatch = inputOptions.filter(function (option) {
					return option.displayValue === dataValue;
				});
				displayValueMatch = displayValueMatch.length ? displayValueMatch[0] : null;

				if (valueMatch) {
					// If the value matches an allowed value
					value = dataValue;
				} else if (displayValueMatch) {
					// If the value doesn't match an allowed value, look for a matching displayValue
					value = displayValueMatch.value;
				} else {
					// If the value doesn't match an allowed value or displayValue, ignore it as invalid
					value = null;
				}

				inputs[inputName].value = value;
			};

			if (config.data) {
				try {
					data = JSON.parse(config.data.FormData);
					state = JSON.parse(config.data.FormState);

					if (config.data.Token) {
						state.appReference = config.data.Token;
					}
					if (config.data.Guid) {
						state.guid = config.data.Guid;
					}
					if (config.data.CentrixResult) {
						state.centrixResult = config.data.CentrixResult;
					}

					if (state) {
						initialState = state;
					}

					for (inputName in data) {
						if (dateInputNames.indexOf(inputName) !== -1) {
							// AngularJS throws errors if trying to initialise
							// an input type="date" with a string instead of a
							// Date object
							inputs[inputName].value = logic.utils.decodeDate(data[inputName]);
						} else if (numberInputNames.indexOf(inputName) !== -1) {
							// AngularJS throws errors if trying to initialise
							// an input type="number" with a string instead of a
							// number
							inputs[inputName].value = parseFloat(data[inputName]);
						} else if (inputName in config.loanApplication.inputOptions) {
							findMatchingValue(inputName);
						} else if (inputs[inputName]) {
							// Ignore any inputs in the data that don't exist in inputs`
							if (data[inputName] !== null) {
								// Don't try to initialise the values of inputs without values
								inputs[inputName].value = data[inputName];
							}
						}
					}

					// Convert address fields value into its object,
					// for backwards compatability with before
					// the address component was created
					if (data.AddressStreetName && !data.Address) {
						inputs.Address.value = {
							Unit: data.AddressUnit,
							StreetNumber: data.AddressStreetNumber,
							StreetName: data.AddressStreetName,
							StreetType: data.AddressStreetType,
							Suburb: data.AddressSuburb,
							City: data.AddressCity,
							Postcode: data.AddressPostcode
						};
					}

					// OLAI Data Format Fixes
					if (inputs.AddressDurationMonth.value && inputs.AddressDurationMonth.value.length === 1) {
						// e.g. '8' not '08'
						inputs.AddressDurationMonth.value = '0' + inputs.AddressDurationMonth.value;
					}

					if (inputs.EmploymentTimeMonth.value && inputs.EmploymentTimeMonth.value === '0') {
						// default int
						inputs.EmploymentTimeMonth.value = null;
					} else if (inputs.EmploymentTimeMonth.value && inputs.EmploymentTimeMonth.value.length === 1) {
						// e.g. '8' not '08'
						inputs.EmploymentTimeMonth.value = '0' + inputs.EmploymentTimeMonth.value;
					}

					if (inputs.EmploymentTimeYear.value && inputs.EmploymentTimeYear.value === '0') {
						// default int
						inputs.EmploymentTimeYear.value = null;
					}

				} catch (e) {
					logic.logging.logJavaScriptError(e);
					console.error('There was a problem reading the saved form data or state.');
					throw e;
				}
			}

			if (localStorageSupport) {
				loanAmount = parseInt(localStorage.getItem(localStorageKeys.calculatorAmount), 10);

				if (loanAmount) {
					inputs.LoanAmount.value = loanAmount;
				}
			}

			if (config.loanApplication) {
				step = config.loanApplication.steps.indexOf(document.location.pathname);

				if (step !== -1) {
					initialState.step = step + 1;
				}
			}
		};
		importData();

		if (!config.debug) {
			window.addEventListener('beforeunload', logic.formControl.leavePrompt);
		}

		Teraform.form.init({
			// Required
			$scope: $scope,
			inputs: inputs,
			// Optional
			actions: actions,
			conditions: conditions,
			state: initialState
		});

		let isStepMissingData = function (step, inputsToIgnore) {
			// Retrieve a "silent" validation summary
			var validationSummary = Teraform.form.getValidationSummary($scope, $scope.stepInputs[step], true);

			var inputName;
			var input;

			var missingData = false;

			inputsToIgnore = inputsToIgnore || [];

			// If there were any errors, check them all
			if (validationSummary.errors > 0) {
				for (inputName in validationSummary.inputs) {
					input = validationSummary.inputs[inputName];

					if (inputsToIgnore.indexOf(inputName) !== -1) {
						// Skip specified fields to be ignored
						continue;
					}

					if (input.valid === true) {
						// Don't bother checking valid fields
						continue;
					}

					// If there is a "showFieldOrSummary" condition for this input, make sure it's true
					if (!conditions.showFieldOrSummary[inputName] || conditions.showFieldOrSummary[inputName]()) {
						// This field is missing data only if its validation error is of the "REQUIRED" type
						missingData = input.errorType === ErrorTypes.REQUIRED;

						if (missingData) {
							// Once we've found one missing field, we can stop
							break;
						}
					}
				}
			}

			return missingData;
		};

		step2MissingData = isStepMissingData(2, ['Gender', 'DateOfBirth']);
		step3MissingData = isStepMissingData(3, []);

		logic.preferredBranch.setRecommendedBranchText();
		logic.preferredBranch.setDefaultRecommendedBranch();

		logic.logging.showDataError();
		logic.logging.collectCheckSend();

		logic.tooltipAttention.init();
		logic.referrer.setLoanDetails();

		logic.referrer.validateQuerystring();

		// Bind escape key to hide help sections
		Keybinding.bind('escape',
			function () {
				var $help = $(selectors.help).filter(':visible[aria-hidden="false"]');

				if ($help.length) {
					publish(pubsubEvents.expandCollapseClose, [$help]);
				}
			},
			true);

		$scope.content = config.content;
		$scope.loaded = true;
	};
	let app = {
		name: 'teraform-loan-app',
		props: ['config'],
		data() {
			return {
				inputs: null,
				state: null,
				conditions: null,
				actions: null,
				loaded: false,
				content: null,
				localState: null,
			}
		},
		mounted() {
			setup(this, this.config);

			$(selectors.appFormForm).removeClass('vue-cloak');

			let recaptchaScript = document.createElement('script');
			recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
			recaptchaScript.setAttribute('id', 'recaptchaScript');
			document.head.appendChild(recaptchaScript);
		},
		methods: {
			setFormDirtyState() {
				isFormDirty = true;
			}
		},
		beforeDestroy() {
			let el = document.getElementById('recaptchaScript')
			if (el) { el.remove() }
		}
	};
	export default app;
</script>
