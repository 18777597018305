import $ from 'jquery';
import { createApp } from 'vue/dist/vue.esm-bundler.js'

import fieldModuleCheckbox from 'Vue/teraform/fields/checkbox';
import fieldModuleCheckboxlist from 'Vue/teraform/fields/checkboxlist';
import fieldModuleDate from 'Vue/teraform/fields/date';
import fieldModuleFile from 'Vue/teraform/fields/file';
import fieldModuleRadio from 'Vue/teraform/fields/radio';
import fieldModuleRange from 'Vue/teraform/fields/range';
// import fieldModuleRangeSelector from 'Vue/teraform/fields/rangeSelector'; // ignored as redundant
import fieldModuleSelect from 'Vue/teraform/fields/select';
import fieldModuleText from 'Vue/teraform/fields/text';
import fieldModuleTextArea from 'Vue/teraform/fields/textarea';
// IF CUSTOM
import fieldModuleCurrency from 'Vue/teraform/fields/currency';
import fieldModuleAnniversary from 'Vue/teraform/fields/anniversary';
import fieldModuleAddress from 'Vue/teraform/fields/address';
import fieldModuleCountdown from 'Vue/teraform/fields/countdown';
import fieldModuleIllion from 'Vue/teraform/fields/illion';
import fieldModuleFileUploaded from 'Vue/teraform/fields/fileUploaded';
import fieldModuleTextNoOptional from 'Vue/teraform/fields/text-no-optional';

const fields = [{
	name: 'teraform-checkbox',
	module: fieldModuleCheckbox
}, {
	name: 'teraform-checkboxlist',
	module: fieldModuleCheckboxlist
}, {
	name: 'teraform-date',
	module: fieldModuleDate
}, {
	name: 'teraform-file',
	module: fieldModuleFile
}, {
	name: 'teraform-radio',
	module: fieldModuleRadio
}, {
	name: 'teraform-range',
	module: fieldModuleRange
}, {
	name: 'teraform-select',
	module: fieldModuleSelect
}, {
	name: 'teraform-text',
	module: fieldModuleText
}, {
	name: 'teraform-textarea',
	module: fieldModuleTextArea
}, {
	name: 'teraform-currency',
	module: fieldModuleCurrency
}, {
	name: 'teraform-anniversary',
	module: fieldModuleAnniversary
}, {
	name: 'teraform-address',
	module: fieldModuleAddress
}, {
	name: 'teraform-countdown',
	module: fieldModuleCountdown
}, {
	name: 'teraform-illion',
	module: fieldModuleIllion
}, {
	name: 'teraform-fileUploaded',
	module: fieldModuleFileUploaded
}, {
	name: 'teraform-text-no-optional',
	module: fieldModuleTextNoOptional
}];

import formModuleContact from 'Vue/teraform/forms/contact';
import formModuleLoanPayment from 'Vue/teraform/forms/loan-payment';
import formModuleLoanCalculator from 'Vue/teraform/forms/loan-calculator';
import formModuleReferAFriend from 'Vue/teraform/forms/refer-a-friend';

const forms = [{
	name: 'teraform-contact',
	module: formModuleContact,
	selector: '.vue-teraform-contact'
}, {
	name: 'teraform-loan-payment',
	module: formModuleLoanPayment,
	selector: '.vue-teraform-loan-payment'
}, {
	name: 'teraform-loan-calculator',
	module: formModuleLoanCalculator,
	selector: '.vue-teraform-loan-calculator'
}, {
	name: 'teraform-refer-a-friend',
	module: formModuleReferAFriend,
	selector: '.vue-teraform-refer-a-friend'
}];

import { LoanApplicationLoader } from 'Vue/teraform/loan-application/loader';

let module = {
	init() {
		//console.log({
		//	message: 'Teraform.init',
		//	forms,
		//	fields,
		//});

		for (let i = 0; i < forms.length; i++) {
			let form = forms[i];

			if ($(form.selector).length) {
				const formApp = createApp({});
				formApp.component(form.name, form.module);
				//console.log({
				//	message: 'register form',
				//	name: form.name
				//});
				for (let j = 0; j < fields.length; j++) {
					let field = fields[j];
					formApp.component(field.name, field.module);
					//console.log({
					//	message: 'register field',
					//	name: field.name
					//});
				}

				formApp.mount(form.selector);
				//console.log({
				//	message: 'mount form',
				//	name: form.selector
				//});
			}

		}

		LoanApplicationLoader.init(fields);
	}
};

export { module as TeraFormLoader };