<template>
	<div v-if="state" class="c-refer__form-wrapper js-refer-form-wrapper">
		<h3 v-if="config.form.content.FormHeading" class="c-refer__form-heading t-pseudo-border">{{config.form.content.FormHeading}}</h3>
		<form class="c-refer__form c-form js-refer-form"
			  method="post"
			  :action="config.form.action"
			  @submit.prevent="actions.validate($event, actions.handleSubmit, inputs)"
			  v-show="state.complete === false"
			  novalidate>
			<input type="hidden" name="g-Recaptcha-Response" />
			<slot />
			<div class="c-form__field" v-show="inputs.Name.condition()">
				<teraform-text :input="inputs.Name" />
			</div>
			<div class="c-form__field" v-show="inputs.Branch.condition()">
				<teraform-select :input="inputs.Branch" />
			</div>
			<div class="c-form__field">
				<teraform-text :input="inputs.FriendName" />
			</div>
			<div class="c-form__field">
				<teraform-text :input="inputs.FriendPhone" />
			</div>
			<div class="c-form__field">
				<teraform-text :input="inputs.FriendEmail" />
			</div>
			<input type="submit" title="Submit" class="c-refer__form-submit o-btn o-btn--brand o-btn--primary" />
			<p class="c-form__recaptcha-disclaimer">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
		</form>
		<div v-show="state.complete">
			<div class="c-refer__form-message" v-show="state.success" v-html="config.form.content.ConfirmationMessage" />
			<div class="c-refer__form-message" v-show="!state.success" v-html="config.form.content.FailureMessage" />
		</div>
	</div>
</template>
<script>
	import $ from 'jquery';
	import { Teraform } from 'Vue/teraform/teraform';
	import { teraformCommon, Patterns, Rules } from 'Vue/teraform/common';
	import { recaptcha } from 'App/recaptcha/recaptcha';
	import * as util from 'Util/core';

	var branchId = '';
	var branchName = '';
	var branchEmail = '';

	let setup = function ($scope, config) {

		let initialState = {
			success: false,
			complete: false,
			step: 1,
			errors: 0,
			foundMatchingBranch: false,
			recaptchaInitialised: false,
		};
		$scope.state = initialState;

		//console.log({
		//	method: 'setup',
		//	$scope,
		//	config
		//});

		const selectors = Object.freeze({
			form: '.js-refer-form'
		});

		let logic = {

			_getBranches: function () {
				var values = [];
				var i;
				var branch;

				for (i = 0; i < config.branches.length; i++) {
					branch = {
						displayValue: config.branches[i].name,
						value: config.branches[i].id + ''
					};

					values.push(branch);
				}
				values = [
					{
						displayValue: 'Please select',
						value: ''
					}
				].concat(values);

				return values;
			},

			_updateBranchDetails: function () {
				var val = inputs.Branch.value;

				var branch = config.branches.filter(function (b) {
					return b.id.toString() === val;
				});

				if (branch.length) {
					branch = branch[0];

					branchId = val;
					branchEmail = branch.email;
					branchName = branch.name;
				}
			},

			_selectDefaultBranchIfOnlyOption: function () {
				var input = inputs.Branch;

				var branches = input.values;
				var defaultBranch;

				if (!conditions.hasMultipleBranches()) {
					defaultBranch = branches.filter(function (branch) {
						return branch.value;
					});

					if (defaultBranch.length) {
						input.value = defaultBranch[0].value;
						input.update();
					}
				}
			},

			_setCustomerValues: function (fields) {
				if (typeof config.customer === 'undefined') {
					return;
				}

				if (config.customer.id) {
					fields.CustomerNumber.value = config.customer.id;
				}

				if (config.customer.name) {
					fields.Name.value = config.customer.name;
				}

				if (config.customer.branch) {
					var fieldValues = fields.Branch.values;

					// match customer value to OLAI API values
					var matchingBranches = config.branches.filter(function (b) {
						return b.creditPlusName === config.customer.branch || b.name === config.customer.branch;
					});

					// matching branch should be unique
					if (matchingBranches.length === 1) {
						var customerBranch = matchingBranches[0];

						for (var i = 0, l = fieldValues.length; i < l; i++) {

							if (customerBranch.name === fieldValues[i].displayValue) {
								$scope.state.foundMatchingBranch = true;

								fields.Branch.value = fieldValues[i].value;
								logic._updateBranchDetails(fieldValues[i].value);

								break;
							}

						}
					}

					if (fields.Branch.value === null) {
						branchId = config.online.id;
						branchName = config.online.name;
						branchEmail = config.online.email;
					}
				}
			},

			submit: {
				recaptchaSubmitHandler: function (e, data, validationSummary) {

					recaptcha.refreshToken()
						.then(function () {
							logic.submit.ajaxSubmit($(selectors.form)[0], data, validationSummary);
						})
						.catch(logic.submit.error);
				},
				before: function (data) {
					$scope.state.busy = true;

					data.set('Branch', branchId);
					data.append('BranchName', branchName);
					data.append('BranchEmail', branchEmail);
					if (inputs.CustomerNumber.value !== null) {
						data.append('CustomerNumber', inputs.CustomerNumber.value);
					}
				},
				success: function (response) {
					$scope.state.busy = false;
					if (response.status === 200) {
						response.json().then(result => {
							$scope.state.complete = true;
							$scope.state.success = result;
						});
					} else {
						$scope.state.complete = true;
						$scope.state.success = false;
					}
					logic.submit._scrollToFormTop();
				},
				error: function () {
					$scope.state.busy = false;

					$scope.state.complete = true;
					$scope.state.success = false;

					logic.submit._scrollToFormTop();
				},

				_scrollToFormTop: function () {
					util.scrollToElm($(selectors.form), 100);
				}
			},
			recaptcha: {
				init: function (apiKey) {
					recaptcha.init({
						apiKey: apiKey,
						action: 'submit_form',
					});
					$scope.state.recaptchaInitialised = true;
				},
			},
		};

		logic.submit.ajaxSubmit = teraformCommon.Handlers.AJAX({
			beforeSubmit: logic.submit.before,
			success: logic.submit.success,
			error: logic.submit.error,
			formData: true,
		});

		let conditions = {
			isBusy: function () {
				return $scope.state.busy === true;
			},
			isComplete: function () {
				return $scope.state.complete === true;
			},
			isBusyOrComplete: function () {
				return conditions.isComplete() || conditions.isBusy();
			},
			isLoggedIn: function () {
				return !!(config.customer && config.customer.id.length > 0);
			},
			foundMatchingBranch: function () {
				return $scope.state.foundMatchingBranch === true;
			},
			hasMultipleBranches: function () {
				// Compare with 2 to also include "Please select" default option
				return inputs.Branch.values.length > 2;
			},
			userPreferredBranchNotFound: function () {
				return $scope.conditions.foundMatchingBranch() === false;
			},
			showBranchInput: function () {
				// There must be branches to choose from,
				// but don't show it if the user is logged
				// in and has their preferred branch pre-set

				return conditions.hasMultipleBranches() && conditions.userPreferredBranchNotFound();
			}
		};

		let validation = function (req, regex, length) {
			return {
				required: req,
				pattern: regex,
				maxlength: length
			}
		};

		let inputs = {
			Name: {
				validation: validation(true, Patterns.NAME, 100),
				condition: function () {
					return $scope.conditions.isLoggedIn() === false;
				},
				label: 'Your full name',
				errors: {
					errorRequired: 'Please enter your full name',
					errorPattern: 'Our system does not support digits or special characters in a name'
				}
			},
			Branch: {
				validation: Rules.REQUIRED,
				value: null,
				values: logic._getBranches(),
				update: logic._updateBranchDetails,
				init: logic._selectDefaultBranchIfOnlyOption,
				condition: conditions.showBranchInput,
				label: 'Your Instant Finance branch',
				errors: {
					errorRequired: 'Please select a branch'
				}
			},
			FriendName: {
				validation: validation(true, Patterns.NAME, 100),
				label: 'Friend\'s name',
				errors: {
					errorRequired: 'Please enter your friend\'s name',
					errorPattern: 'Our system does not support digits or special characters in a name'
				}
			},
			FriendPhone: {
				validation: validation(true, Patterns.PHONE, 100),
				label: 'Friend\'s phone number',
				errors: {
					errorRequired: 'Please enter your friend\'s phone number',
					errorPattern: 'Please add a valid phone number'
				}
			},
			FriendEmail: {
				validation: validation(true, Patterns.EMAIL, 255),
				label: 'Friend\'s email address',
				type: 'email',
				errors: {
					errorRequired: 'Please enter your friend\'s email address',
					errorPattern: 'Please enter a valid email address'
				}
			},
			CustomerNumber: {
				value: null
			}
		};
		inputs = Teraform.initInputs(inputs);
		$scope.inputs = inputs;

		let actions = {
			handleSubmit: logic.submit.recaptchaSubmitHandler,
			initRecaptcha: logic.recaptcha.init,
		};

		Teraform.form.init({
			// Required
			$scope: $scope,
			inputs: inputs,

			// Optional
			actions: actions,
			conditions: conditions,
			state: initialState
		});

		logic._setCustomerValues(inputs);
		logic.recaptcha.init(config.form.recaptcha_key);
	}

	let app = {
		name: 'teraform-refer-a-friend',
		props: ['config'],
		data() {
			return {
				inputs: null,
				state: null,
				conditions: null,
				actions: null,
			}
		},
		mounted() {
			setup(this, this.config);
			//console.log({
			//	method:'mounted()',
			//	task: 'teraform-loan-payment',
			//	config: this.config,
			//	fields: toRaw(this.fields),
			//	inputs: core.inputs
			//});
		},
		methods: {
		}
	};

	export default app;
</script>