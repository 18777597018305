<template>
	<div class="c-app-form__step">
		<teraform-help-panel :panel="content.Step1Help" />

		<form id="app-form-step-1"
			  class="c-form c-app-form__form js-app-form__step"
			  method="post"
			  action="."
			  @submit.prevent="actions.validateWithErrorLogging($event, actions.submitStep, stepInputs[state.step])"
			  novalidate>
			<!-- Browsers bind the "enter" key in a form containing buttons to click the first button. Since the first button in this form is not a submit button, put this inaccessible button here to make sure "enter" tries to submit the form as usual. -->
			<button class="u-visuallyhidden" tabindex="-1" aria-hidden="true">Submit</button>
			<!-- Sent to pre-save check using FormData, but not sent to "save" endpoint using JSON interface -->
			<input type="hidden" name="g-Recaptcha-Response" />

			<div v-for="referrer in content.ReferrerSettings">
				<div class="c-app-form__referral" v-if="(!!referrer.PanelLogo || !!referrer.PanelHeading || !!referrer.PanelIntroduction) && conditions.isReferreredBy(referrer.Name)">
					<div class="c-app-form__referral-logo" v-if="!!referrer.PanelLogo">
						<span class="o-fit" :style="'padding-bottom:'+referrer.PanelLogo.padding+'%;'">
							<img :src="referrer.PanelLogo.Url" :alt="referrer.PanelLogo.AlternateText" class="o-fit__image" />
						</span>
					</div>
					<!--@if (Model.IsBrokerMode && Processor.GetOlaiBrokerData(User) != null)
					{
					<h3 class="c-form__section-title">Welcome @Processor.GetOlaiBrokerData(User).Name,</h3>
					}-->
					<h2 class="c-form__section-title" v-if="referrer.PanelHeading">{{referrer.PanelHeading}}</h2>
					<div class="o-body-text c-app-form__referral-intro" v-if="referrer.PanelIntroduction" v-html="referrer.PanelIntroduction" />
				</div>
			</div>

			<h2 class="c-app-form__step-title">{{content.Step1Title}}</h2>
			<p class="c-app-form__step-intro" v-show="conditions.isLoggedOut()">All fields are required unless specified otherwise</p>

			<div class="c-app-form__alert" v-if="content.Step1Introduction">
				<div class="c-notification">
					<div class="c-notification__bar">
						<span class="c-notification__icon iconf-information" aria-hidden="true"></span>
					</div>
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step1Introduction" />
					</div>
				</div>
			</div>

			<div class="c-app-form__alert" v-show="conditions.isLoggedIn()" v-if="content.Step1LoggedInIntroduction">
				<div class="c-notification">
					<div class="c-notification__bar">
						<span class="c-notification__icon iconf-information" aria-hidden="true"></span>
					</div>
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.Step1LoggedInIntroduction" />
					</div>
				</div>
			</div>

			<div class="c-app-form__secondary" v-show="conditions.isSecondaryApplication()" v-if="content.CoborrowerIntroductionMessage">
				<div class="c-app-form__secondary-notification c-notification c-notification--dismissable">
					<div class="c-notification__bar">
						<span class="c-notification__icon iconf-information" aria-hidden="true"></span>
					</div>
					<div class="c-notification__body">
						<div class="c-notification__content" v-html="content.CoborrowerIntroductionMessage" />
					</div>
				</div>
			</div>

			<div class="c-app-form__prefill" v-if="conditions.isAmountLocked() || conditions.isPurposeLocked()">
				<dl class="c-app-form__prefill-list">
					<div class="c-app-form__prefill-item" v-if="conditions.isAmountLocked()">
						<dt class="c-app-form__prefill-label">Loan amount:</dt>
						<dd class="c-app-form__prefill-value">{{inputs.LoanAmountDescription.value}}</dd>
					</div>
					<div class="c-app-form__prefill-item" v-if="conditions.isPurposeLocked()">
						<dt class="c-app-form__prefill-label">Loan purpose:</dt>
						<dd class="c-app-form__prefill-value">{{localState?.lockedPurpose}}</dd>
					</div>
					<div class="c-app-form__prefill-item" v-if="inputs.ReferrerData.value">
						<dt class="c-app-form__prefill-label">Products:</dt>
						<dd class="c-app-form__prefill-value">
							<ul class="o-list--reset">
								<li v-for="product in inputs.ReferrerData.value">
									{{product?.Name}} x {{product?.Quantity}}
								</li>
							</ul>
						</dd>
					</div>
				</dl>
			</div>

			<!-- BROKER EMAIL ADDRESS -->
			<section class="c-form__section" v-show="conditions.isBroker()">
				<h3 class="c-form__section-title">
					<label for="BrokerEmailSubmission">{{content.FieldLabelBrokerSubmissionEmail}}</label>
				</h3>
				<div class="c-form__field">
					<teraform-text :input="inputs.BrokerEmailSubmission" />
				</div>
			</section>
			<!-- /BROKER EMAIL ADDRESS -->

			<!-- LOAN AMOUNT -->
			<section class="c-form__section" v-show="conditions.showAmount()">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="LoanAmount">{{content.FieldLabelLoanAmount}}</label>
					</h3>
					<teraform-tooltip :text="content.FieldTooltipLoanAmount" />
				</div>

				<div class="c-form__field">
					<div v-show="conditions.showFieldOrSummary.LoanAmount()">
						<teraform-range :input="inputs.LoanAmount" />

						<button class="o-btn--link t-link" @click="actions.toggleSpecificLoanAmount($event)" aria-live="polite" :aria-expanded="state.specifyLoanAmount">
							<span v-if="state.specifyLoanAmount !== true">
								<span class="o-btn__icon iconf-plus" aria-hidden="true"></span><span>&nbsp;</span>
								<span v-if="content.FieldToggleTextLoanSpecificAmountEnable">{{content.FieldToggleTextLoanSpecificAmountEnable}}</span>
								<span v-else>Need more or a more specific amount?</span>
							</span>
							<span v-if="state.specifyLoanAmount === true">
								<span class="o-btn__icon iconf-minus" aria-hidden="false"></span><span>&nbsp;</span>
								<span v-if="content.FieldToggleTextLoanSpecificAmountDisable">{{content.FieldToggleTextLoanSpecificAmountDisable}}</span>
								<span v-else>Use the slider instead</span>
							</span>
						</button>
					</div>
					<teraform-currency :input="inputs.LoanAmountSpecific" />
				</div>

				<!-- BROKER FIELDS -->
				<div v-show="conditions.isBroker()">

					<div class="c-form__field">
						<teraform-currency :input="inputs.BrokerRefinance" />
					</div>

					<div class="c-form__field">
						<teraform-currency :input="inputs.BrokerCashDeposit" />
					</div>

					<div class="c-form__field">
						<teraform-currency :input="inputs.BrokerTradeIn" />
					</div>

					<div class="c-form__field">
						<teraform-currency :input="inputs.BrokerFee" />
					</div>

					<div class="c-form__field" v-if="inputs.BrokerOptInCci.condition()">
						<teraform-radio :input="inputs.BrokerOptInCci" />
					</div>

					<div class="c-form__field">
						<teraform-currency :input="inputs.BrokerCciLpi" />
					</div>

					<div class="c-form__field">
						<teraform-currency :input="inputs.BrokerMbi" />
					</div>

					<div class="c-form__field">
						<teraform-currency :input="inputs.BrokerGap" />
					</div>
				</div>
				<!-- /BROKER FIELDS -->

			</section>
			<!-- /LOAN AMOUNT -->

			<section class="c-form__section" v-show="conditions.showPurpose()">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="LoanPurpose">{{content.FieldLabelLoanPurpose}}</label>
					</h3>
					<teraform-tooltip :text="content.FieldTooltipLoanPurpose" />
				</div>
				<div class="c-form__field">
					<teraform-select :input="inputs.LoanPurpose" />
				</div>
				<div class="c-form__field" v-show="conditions.showPurposeOther()">
					<teraform-text :input="inputs.LoanPurposeOther" />
				</div>
			</section>

			<!-- FULL NAME -->
			<section class="c-form__section" v-show="conditions.isLoggedOut()">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">{{content.SectionLabelName}}</h3>
					<teraform-tooltip :text="content.SectionTooltipName" />
					<span class="c-form__section-description" v-if="content.SectionDescriptionName">{{content.SectionDescriptionName}}</span>
				</div>

				<div class="c-form__field">
					<teraform-radio :input="inputs.Title" />
				</div>

				<div class="c-form__field">
					<teraform-text :input="inputs.FirstName" />
				</div>

				<div class="c-form__field">
					<teraform-text-no-optional :input="inputs.MiddleName" />
				</div>

				<div class="c-form__field">
					<teraform-text :input="inputs.LastName" />
				</div>
			</section>

			<section class="c-form__section" v-show="conditions.isStepLocked(1)">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">{{content.SectionLabelPersonalInfo}}</h3>
					<teraform-tooltip :text="content.SectionTooltipPersonalInfo" />
				</div>
				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="NameLabel">{{content.FieldLabelName}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="NameLabel">
						{{inputs.Title.value}} {{inputs.FirstName.value}} {{inputs.MiddleName.value}} {{inputs.LastName.value}}
					</span>
				</div>
				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="EmailLabel">{{content.FieldLabelEmail}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="EmailLabel">{{inputs.Email.value}}</span>
				</div>
				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="ContactNumberLabel">{{inputs.ContactNumberType.value}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="ContactNumberLabel">{{inputs.ContactNumber.value}}</span>
				</div>
				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="DateOfBirthLabel">{{content.FieldLabelDateOfBirth}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="DateOfBirthLabel">
						<span v-if="conditions.hasValue(inputs.DateOfBirth.value)">{{inputs.DateOfBirth.value}}</span><! -- {{inputs.DateOfBirth.value | date: 'dd MMMM yyyy'}} -- >
						<span v-if="!conditions.hasValue(inputs.DateOfBirth.value)" class="c-form__missing">Unknown</span>
					</span>
				</div>
				<div class="c-form__field c-form__field--close c-form__field--row">
					<span class="c-form__label" id="GenderLabel">{{content.FieldLabelGender}}</span>
					<span class="c-form__input c-form__input--readonly" aria-labelledby="GenderLabel">
						<span v-if="inputs.Gender.value">{{inputs.Gender.value}}</span>
						<span v-if="!inputs.Gender.value" class="c-form__missing">Unknown</span>
					</span>
				</div>
			</section>
			<!-- /FULL NAME -->

			<!-- EMAIL ADDRESS -->
			<section class="c-form__section" v-show="conditions.isLoggedOut()">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="Email">{{content.FieldLabelEmail}}</label>
					</h3>
					<teraform-tooltip :text="content.FieldTooltipEmail" />
				</div>

				<div class="c-form__field">
					<teraform-text :input="inputs.Email" />
				</div>
			</section>
			<!-- /EMAIL ADDRESS -->

			<!--IF-138-->
			<section class="c-form__section" v-show="conditions.isPrimaryApplication()">
				<div class="c-form__section-title-split">
					<h3 class="c-form__section-title">
						<label for="JointApplication-0">{{content.FieldLabelJointApplication}}</label>
					</h3>
				</div>
				<div class="c-form__fine-print" v-if="content.FieldTooltipJointApplication" v-html="content.FieldTooltipJointApplication" />
				<div class="c-form__field">
					<teraform-radio :input="inputs.JointApplication" />
				</div>

				<div v-show="conditions.showJointApplicantFields()">
					<p v-if="content.InstructionsJointApplication">{{content.InstructionsJointApplication}}</p>
					<div class="c-form__field">
						<teraform-text :input="inputs.JointApplicantFirstName" />
					</div>
					<div class="c-form__field">
						<teraform-text :input="inputs.JointApplicantLastName" />
					</div>
					<div class="c-form__field">
						<teraform-text :input="inputs.JointApplicantEmail" />
					</div>
					<div class="c-form__field">
						<teraform-text :input="inputs.JointApplicantPhoneNumber" />
					</div>
				</div>
			</section>

			<section class="c-form__section">
				<h3 class="c-form__section-title">{{content.SectionLabelPrivacyPolicy}}</h3>

				<div class="c-form__fine-print" v-html="content.SectionTextPrivacyPolicy" />

				<div class="c-form__field c-form__field-checkbox">
					<div class="c-form__field-checkbox-input">
						<teraform-checkbox :input="inputs.AgreedToPrivacyPolicy" />
					</div>
					<div class="c-form__field-checkbox-label">
						<label for="AgreedToPrivacyPolicy" class="c-form__label c-form__label--rte t-link-parent" v-html="content.FieldLabelPrivacyPolicy" />
						<p class="c-form__error" v-if="inputs.AgreedToPrivacyPolicy.error === 'required'">You must agree to the Privacy Policy</p>
					</div>
				</div>

				<div class="c-form__field c-form__field-checkbox" v-show="conditions.isLoggedOut()">
					<div class="c-form__field-checkbox-input">
						<teraform-checkbox :input="inputs.AgreedToElectronicCommunication" />
					</div>
					<div class="c-form__field-checkbox-label">
						<label for="AgreedToElectronicCommunication" class="c-form__label c-form__label--rte t-link-parent" v-html="content.FieldLabelElectronicCommunication" />
					</div>
				</div>

				<div class="c-form__field c-form__field-checkbox" v-if="inputs.BrokerAccuracyConfirmation.condition()">
					<div class="c-form__field-checkbox-input">
						<teraform-checkbox :input="inputs.BrokerAccuracyConfirmation" />
					</div>
					<div class="c-form__field-checkbox-label">
						<label for="BrokerAccuracyConfirmation" class="c-form__label c-form__label--rte t-link-parent" v-html="content.FieldLabelBrokerAccuracyConfirmation" />
						<p class="c-form__error" v-if="inputs.BrokerAccuracyConfirmation.error === 'required'">You must confirm that this information is accurate</p>
					</div>
				</div>
			</section>

			<div class="c-form__controls">
				<div class="c-form__controls-fallback" v-if="conditions.recaptchaFallbackRequired()">
					<div class="c-form__controls-fallback-error c-form__error" v-html="content.ReCaptchaMessage"/>
					<div v-if="actions.initRecaptchaFallback()" id="recaptcha" class="g-recaptcha" data-theme="clean" data-size="normal" :data-sitekey="config.recaptcha.fallback"></div>
					<input type="hidden" name="g-Recaptcha-Fallback" value="true" />
				</div>
				<span class="c-app-form__back"><!-- Back button placeholder for flex layout --></span>
				<p class="c-form__controls-text c-app-form__submit-text">{{content.ApplicationSaveStatement}}</p>
				<button class="o-btn o-btn--supporting o-btn--large o-btn--nowrap c-app-form__submit" type="submit" :disabled="conditions.canSubmitStep1() === false" :class="{'c-loader--small': conditions.isBusy()}">Next</button>
			</div>

			<a class="c-form__exit"
			   :target="content.ReturnToPortalLink.Target"
			   :href="content.ReturnToPortalLink.Url"
			   v-if="conditions.isLoggedIn() && content.ReturnToPortalLink">
				{{content?.ReturnToPortalLink?.Name}}
			</a>

			<p class="c-form__recaptcha-disclaimer">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank">Terms of Service</a> apply.</p>
		</form>

		<div class="c-app-form__step-info" v-if="content.Step1FooterDisclaimer" v-html="content.Step1FooterDisclaimer" />
	</div>
</template>
<script>
	let app = {
		name: 'teraform-step1',
		props: [
			'inputs',
			'content',
			'actions',
			'state',
			'conditions',
			'stepInputs',
			'localState',
			'config',
		],
		data() {
			return {}
		},
		beforeCreate() {
		},
		created() {
		},
		beforeMount() {
		},
		mounted() {
			//let $scope = {
			//	inputs: this.inputs,
			//	content: this.content,
			//	actions: this.actions,
			//	state: this.state,
			//	conditions: this.conditions
			//};
			//console.log('teraform-step1', $scope);
		},
		methods: {}
	};
	export default app;
</script>