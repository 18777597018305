<template>
	<div v-if="loaded">
		<form class="c-form js-app-form__confirmation"
			  method="post"
			  :action="config.action"
			  @submit.prevent="actions.validate($event, actions.handleSubmit)"
			  novalidate>
			<teraform-illion :input="inputs.Illion" />
			<slot />
		</form>
	</div>
</template>
<script>
	import $ from 'jquery';
	import { ref, toRaw } from 'vue';
	import { Teraform } from 'Vue/teraform/teraform';
	import { debounce } from 'Util/debounce';
	import { qs } from 'Util/querystring';

	const selectors = {
		appForm: '.js-app-form',
		appFormStep: '.js-app-form__step',
	};

	let setup = function ($scope, config) {

		let initialState = {
			busy: false,
		};

		let logic = {
			creditSense: {
				// Use debounce to protect against edge cases where
				// Credit Sense returns many response codes in quick succession
				update: debounce(function () {
					var ctrl = this;
					var input = ctrl.input;

					var value = input.value;
					var latestValue;

					if (!value) {
						return;
					}

					// Users can only interact with the latest value,
					// because only one iframe is active at any time
					latestValue = value[value.length - 1];

					if (latestValue.complete || latestValue.criticalError) {
						logic.creditSense.submit(value);
					}
				},
					1000),
				submit: function (value) {
					var $form = $(selectors.form);
					var url = $form.attr('action');

					var querystring = qs.get();
					var guid = querystring.ref;

					url = url + '?ref=' + guid;

					$scope.state.busy = true;

					// This function is only called from within the
					// debounced creditSense.update function, so
					// needs a $scope.$apply()
					//$scope.$apply();
					// $http re-binds it to AngularJS

					// Just using $http.post set the wrong content-type, for some reason

					// TODO-NG - $HTTP
					//$http({
					//	method: 'POST',
					//	url: url,
					//	headers: {
					//		'Content-Type': 'application/json'
					//	},
					//	data: value
					//})
					//	.then(logic._saveValuesSuccess, logic._saveValuesError);
				},
			},
			illion: {
				update: function () {
					//console.log('illion.update');
					var input = $scope.inputs.Illion;
					var value = toRaw(input.value);

					// Illion's update event is called on successful submission of a single
					// bank, and also when the "Finish" button is pressed to submit statements.
					if (input.conditions.isFinished()) {
						logic.illion.submit(value);
					}

					//console.log({
					//	input,
					//	value,
					//	isFinished: ctrl.conditions.isFinished
					//});
				},
				submit: function (value) {
					var $form = $(selectors.form);
					var url = config.action;

					var querystring = qs.get();
					var guid = querystring.ref;

					url = url + '?ref=' + guid;

					$scope.state.busy = true;

					// Just using $http.post set the wrong content-type, for some reason

					let options = {
						method: 'POST',
						url: url,
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(value)
					};
					fetch(url, options)
						.then(x => x.text())
						.then(logic._saveValuesSuccess, logic._saveValuesError);
				},
			},
			_saveValuesSuccess: function (response) {
				// console.log('_saveValuesSuccess', response);
				$scope.state.busy = false;
			},
			_saveValuesError: function (response) {
				// console.error('_saveValuesError', response);
				$scope.state.busy = false;
			},
		};

		let actions = {
			handleSubmit: function (event, data, validationSummary) {
				event.preventDefault();
			}
		};

		let conditions = {
			disabledIfBusy: function () {
				return $scope.state.busy === true;
			}
		};

		let inputs = {
			CreditSense: {
				// Use debounce to protect against edge cases where Credit Sense
				// returns many response codes in quick succession
				update: logic.creditSense.update,
				disabled: conditions.disabledIfBusy
			},

			Illion: {
				update: logic.illion.update,
				appReference: config.token,
				successMessageHtml: config.success,
				config: config.illion,
			},
		};

		if (config) {
			inputs = Teraform.mergeFieldConfig(config.inputs, inputs);
		}
		inputs = Teraform.initInputs(inputs);
		//console.log({ config, inputs });

		Teraform.form.init({
			// Required
			$scope: $scope,
			inputs: inputs,
			// Optional
			actions: actions,
			conditions: conditions,
			state: initialState
		});

		$scope.loaded = true;
	};
	let app = {
		name: 'teraform-loan-confirmation',
		props: ['config'],
		data() {
			return {
				inputs: null,
				state: null,
				conditions: null,
				actions: null,
				loaded: false
			}
		},
		mounted() {
			setup(this, this.config);
			// console.log(this.config);
		},
		methods: {
		}
	};
	export default app;
</script>
