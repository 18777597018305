<template>
	<div class="js-illion"
		 v-show="input.condition()"
		 v-if="loaded"
		 :id="input.htmlId">

		<input type="hidden" :name="input.htmlName" :value="input.postValue" />

		<section class="c-form__section--tight">
			<div class="c-app-form__pane"
				 v-if="input.conditions.showIframe()"
				 :class="{'c-app-form__pane--error' : input.error}">
				<iframe class="c-illion js-illion__iframe"
						sandbox="allow-forms allow-same-origin allow-scripts"
						:src="input.state.iframeSrc"
						></iframe>
			</div>

			<div class="c-app-form__pane c-app-form__pane--status c-app-form__pane--success" v-if="input.conditions.showSuccessMessage()">
				<span class="c-app-form__pane-icon"></span>

				<div class="c-app-form__pane-text" v-html="input.successMessageHtml" />
			</div>
		</section>

		<span class="c-form__error" v-if="input.errors.errorRequired" v-show="input.error === 'required'">{{input.errors.errorRequired}}</span>
	</div>
</template>
<script>
	import $ from 'jquery';
	import { ErrorTypes } from 'Vue/teraform/rules';
	import 'Lib/iframeResizer';

	// Type definitions for Illion messaging system and logging

	/**
	 * @typedef {Object} IllionSuccessMessageData
	 * @property {string} source
	 * @property {string} event
	 * @property {'success'} status
	 * @property {any} [data]
	 */

	/**
	 * @typedef {Object} IllionErrorMessageData
	 * @property {string} source
	 * @property {string} event
	 * @property {'error'} status
	 * @property {number} error_code
	 * @property {string} error_message
	 * @property {any} [data]
	 */

	/**
	 * @typedef {IllionSuccessMessageData | IllionErrorMessageData} IllionMessageData
	 */

	/**
	 * @typedef {IllionMessageData & {appId: string}} IllionLogData
	 */

	// Type definition for saving data

	/**
	 * @typedef {Object} IllionSaveData
	 * @property {'success'} status
	 * @property {string} reference
	 */

	const api = {
		log: '/api/illion/log',
	};

	const IllionEvent = {
		BANK_SELECTED: 'bank_selected',
		LOGIN_CLICKED: 'login_clicked',
		ACCOUNTS_SELECTED: 'accounts_selected',
		SUBMISSION_COMPLETE: 'submission_complete',
		SUBMIT_ALL: 'submit_all',
	};

	const selectors = {
		iframe: '.js-illion__iframe',
	};

	let module = {
		_decodeHtml: function (value) {
			let txt = document.createElement("textarea");
			txt.innerHTML = value;
			return txt.value;
		}
	};

	let appInit = false;

	let initIframe = function (config) {
		if (appInit === false) {
			iFrameResize(
				{
					checkOrigin: [config.multibankUrl]
				},
				$(selectors.iframe)[0]
			);
			appInit = true;
		}
	};

	let setup = function ($input, config, $scope) {
		let app = {
			init: function () {

				$input.value = $input.value || [];

				if ($input.value) {
					app.actions.exportValue($input);
				}

				$input.state = {
					iframeSrc: app.logic.getIframeSrc(),

					/** Has Illion been completed since the last important status change? */
					illionBankCompleted: false,
					illionFinished: false,

					// These are for Illion's messaging system
					attachedCallback: null,
					intervalId: null,
					previousHash: null
				};

				$input.actions = app.actions;
				$input.conditions = app.conditions;

				app.logic.receiveMessage(app.logic.processMessage, config.multibankUrl);
				// $input.successMessageHtml = module._decodeHtml($input.successMessage);

			},
			actions: {
				update: function () {
					app.actions.exportValue($input);
				},
				exportValue: function (input) {
					input.postValue = JSON.stringify(input.value);
				}
			},
			logic: {
				getIframeSrc: function () {
					var appId = app.logic.getAppId();
					var iframeSrc = config.multibankUrl + '/' + config.accountCode + '-webid:' + appId;
					var iframeSrcTrustedResource = iframeSrc;

					return iframeSrcTrustedResource;
				},
				getAppId: function () {
					var appId = $input.appReference;
					return appId;
				},

				/**
				 * Call this function to send a message to the parent iFrame.
				 * param message The message to be sent to the other window.
				 * param target_url The URL of the window that the message should be sent to.
				 * param target An actual reference to the window/frame that the message should be sent to (i.e. a parent window or iFrame).
				 */
				postMessage: function (message, targetUrl, target) {
					//console.log('postMessage', message, targetUrl, target);
					if (!targetUrl) {
						return;
					}
					target = target || parent; // default to parent
					if (window['postMessage']) {
						// the browser supports window.postMessage, so call it with a targetOrigin
						// set appropriately, based on the targetUrl parameter.
						target['postMessage'](message, targetUrl.replace(/([^:]+:\/\/[^\/]+).*/, '$1'));
					} else if (targetUrl) {
						// the browser does not support window.postMessage, so use the window.location.hash fragment hack
						target.location = targetUrl.replace(/#.*$/, '') + '#' + (+new Date) + (cache_bust++) + '&' + message;
					}
				},
				/**
				 * Call this function to start your window listening for messages.
				 *
				 * @param  {Function} [callback] A function to execute after the message has been handled. The message event will be passed to the callback.
				 * @param  {string} [sourceOrigin] A string domain for the authorised domain from which we can receive messages. Messages from other domains are rejected.
				 */
				receiveMessage: function (callback, sourceOrigin) {
					// browser supports window.postMessage

					//console.log({
					//	method: 'receiveMessage',
					//	callback,
					//	sourceOrigin
					//});

					if (window['postMessage']) {
						// bind the callback to the actual event associated with window.postMessage
						if (callback) {
							$input.state.attachedCallback = function (e) {
								// Check if the source origin matches the event origin. If it does, call the callback.

								var noOriginMatchString = typeof sourceOrigin === 'string' && e.origin !== sourceOrigin;
								var noOriginMatchFunction = Object.prototype.toString.call(sourceOrigin) === "[object Function]" && sourceOrigin(e.origin) === false;
								var noOriginMatch = noOriginMatchString || noOriginMatchFunction;

								//console.log({
								//	method: 'receiveMessageAttachedCallback',
								//	noOriginMatchString,
								//	noOriginMatchFunction,
								//	noOriginMatch,
								//	event: e
								//});

								if (noOriginMatch) {
									return false;
								} else {
									callback(e);
								}
							};
						}

						if (window['addEventListener']) {
							window[callback ? 'addEventListener' : 'removeEventListener']('message', $input.state.attachedCallback, false);
						} else {
							window[callback ? 'attachEvent' : 'detachEvent']('onmessage', $input.state.attachedCallback);
						}
					} else {
						// It's not at all clear to me how this is expected to work,
						// but window.postMessage is supported back to IE10 so this code should never be reached

						// a polling loop is started & callback is called whenever the location.hash changes
						if ($input.state.intervalId) {
							clearInterval($input.state.intervalId);
						}
						$input.state.intervalId = null;

						if (callback) {
							$input.state.intervalId = window.setInterval(function () {
								var hash = document.location.hash;
								var pattern = /^#?\d+&/;
								if (hash !== $input.state.previousHash && pattern.test(hash)) {
									$input.state.previousHash = hash;
									callback({ data: hash.replace(pattern, '') });
								}
							},
								100);
						}
					}
				},
				/**
				 * Process a message received from Illion
				 *
				 * @param  {MessageEvent} event
				 */
				processMessage: function (event) {
					var dataString = event.data;

					/** @type {IllionMessageData} */
					var data;

					//console.log({
					//	method: 'processMessage',
					//	event,
					//	dataString
					//});

					if (typeof dataString === 'string') {
						if (dataString.indexOf('[iFrameSizer]') !== -1) {
							// Filter out the messages from the iframe resizer
							return;
						} else {
							try {
								data = JSON.parse(dataString);
								data.appId = app.logic.getAppId();

								// Forward all messages to the audit log
								app.logic.logMessage(data);

								if (data.status === 'success') {
									if (data.event === IllionEvent.SUBMISSION_COMPLETE) {
										// Save a record of any successful submissions
										app.logic.updateValue(data);
										$input.state.illionBankCompleted = true;
									} else if (data.event === IllionEvent.SUBMIT_ALL) {
										$input.state.illionFinished = true;
										$input.update();
									}
								}
							} catch (e) {
								// Ignore any malformed messages received from Illion
							}
						}
					}
				},
				/**
				 * Record a success or error message from Illion in the log
				 *
				 * @param  {IllionLogData} data
				 */

				logMessage: function (data) {
					let options = {
						method: 'POST',
						url: api.log,
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(data)
					};
					fetch(api.log, options);
					// console.log('log message', data);
				},
				/**
				 * Update the input's value array,
				 * either by adding a new object or by updating the last one.
				 *
				 * @param  {IllionMessageData} data
				 */
				updateValue: function (data) {
					if (data.status !== 'success') {
						// Only successes get included in the value
						return;
					}

					var appId = app.logic.getAppId();

					/** @type {IllionSaveData} */
					var saveData = {
						status: data.status,
						reference: config.accountCode + '-webid:' + appId,
					};

					if ($input.state.illionBankCompleted || $input.value.length === 0) {
						$input.value.push(saveData);
						$input.state.illionBankCompleted = false;
					} else {
						$input.value[$input.value.length - 1] = saveData;
					}

					app.actions.update();
				},
			},
			conditions: {
				isFinished: function () {
					var isFinished = $input.state.illionFinished;

					return isFinished;
				},
				showIframe: function () {
					var isFinished = $input.conditions.isFinished();
					var isDisabled = $input.disabled();

					return isDisabled === false && isFinished === false;
				},
				showSuccessMessage: function () {
					var isFinished = $input.conditions.isFinished();
					var isDisabled = $input.disabled();

					return isDisabled === true || isFinished === true;
				}
			}
		};

		$input.componentValidation = function (value) {
			var rules = $input.validation;
			var hasValue = $input.conditions.isFinished();

			if (rules.required) {
				if (hasValue === false) {
					return ErrorTypes.REQUIRED;
				}
			}

			return true;
		};

		app.init();

		$scope.loaded = true;
	};

	export default {
		name: 'teraform-illion',
		props: ['input'],
		data() {
			return {
				loaded: false,
			}
		},
		beforeMount() {
			//console.log('teraform-illion beforeMount', this.input, this.input.config);
		},
		mounted() {
			setup(this.input, this.input.config, this);

			//console.log('teraform-illion mounted', this.input);
		},
		updated() {
			initIframe(this.input.config);
		},
		methods: {
		}
	};
</script>